import React from 'react'
import { useLocation } from 'react-router-dom'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro'
import routes from 'misc/routes'

function getRouteName(pathname, routes) {
  const currentRoute = routes.find((route) => route.path === pathname)

  return currentRoute ? currentRoute.name : false
}

function getBreadcrumbs(location) {
  const breadcrumbs = []

  location.split('/').reduce((prev, curr, index, array) => {
    const currentPathname = `${prev}/${curr}`
    const routeName = getRouteName(currentPathname, routes)

    if (routeName) {
      breadcrumbs.push({ pathname: currentPathname, name: routeName, active: index + 1 === array.length ? true : false })
    }

    return currentPathname
  })

  return breadcrumbs
}

function AppBreadcrumb() {
  const currentLocation = useLocation().pathname
  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })} key={index}>
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
