import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { CBadge } from '@coreui/react-pro'

function AppSidebarNav({ items }) {
  const location = useLocation()

  function getLink(name, icon, badge) {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  function getItem(item, index) {
    const { component, name, badge, icon, ...rest } = item
    const Component = component

    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        {...rest}
      >
        {getLink(name, icon, badge)}
      </Component>
    )
  }

  function getGroup(item, index) {
    const { component, name, icon, to, ...rest } = item
    const Component = component

    return (
      <Component idx={String(index)} key={index} toggler={getLink(name, icon)} visible={location.pathname.startsWith(to)} {...rest}>
        {item.items?.map((item, index) => (item.items ? getGroup(item, index) : getItem(item, index)))}
      </Component>
    )
  }

  return <React.Fragment>{items && items.map((item, index) => (item.items ? getGroup(item, index) : getItem(item, index)))}</React.Fragment>
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AppSidebarNav
