import React, { useState } from 'react'
import PropTypes from 'prop-types'
import xlsx from 'json-as-xlsx'
import { CButton, CSmartTable } from '@coreui/react-pro'
import { cilCloudDownload } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { DropdownFilter } from 'helpers/SmartTableUtils'
import Enums from 'misc/enums'
import UserDetail from 'components/User/UserDetail'

function UserTable({ data, onChange }) {
  const [details, setDetails] = useState([])

  function handleRowClick(data, row, field, e) {
    if (true !== e) {
      setDetails((prevState) => {
        const newState = prevState.slice()
        const { id } = data

        newState.includes(id) ? newState.splice(newState.indexOf(id), 1) : newState.push(id)

        return newState
      })
    }
  }

  function handleRefetch() {
    setDetails([])
    onChange && onChange()
  }

  function handleDownload() {
    const file = [
      {
        sheet: 'Usuários',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'Nome', value: 'name' },
          { label: 'E-mail', value: 'email' },
          { label: 'Perfil', value: 'roles' },
          { label: 'Empresa', value: 'company' },
          { label: 'Cargo', value: 'position' },
          { label: 'Estado', value: 'state' },
          { label: 'Regional', value: 'regional' },
        ],
        content: data,
      },
    ]

    const settings = {
      fileName: `usuarios_${Date.now()}`,
    }

    xlsx(file, settings)
  }

  return (
    <div style={{ position: 'relative' }}>
      <CButton size="sm" color="light" className="download-report" onClick={handleDownload}>
        <CIcon icon={cilCloudDownload} size="lg" /> Download (.xls)
      </CButton>
      <CSmartTable
        tableProps={{ striped: true, hover: true }}
        columns={[
          {
            key: 'name',
            label: 'Nome',
            _style: { width: '220px' },
          },
          {
            key: 'email',
            label: 'E-mail',
          },
          {
            key: 'position',
            label: 'Cargo',
            _style: { width: '200px' },
            filter: DropdownFilter,
          },
          {
            key: 'company',
            label: 'Empresa',
            _style: { width: '200px' },
            filter: DropdownFilter,
          },
          {
            key: 'roles',
            label: 'Perfil',
            _style: { width: '220px' },
            filter: (values, onChange) => DropdownFilter(values, onChange, Enums.roles),
          },
        ]}
        scopedColumns={{
          position: (item) => <td>{item.position}</td>,
          company: (item) => <td>{item.company}</td>,
          roles: (item) => <td>{Enums.roles[item.roles]}</td>,
          details: (item) => <UserDetail id={item.id} visible={details.includes(item.id)} onMutation={handleRefetch} />,
        }}
        columnFilter
        columnSorter
        clickableRows
        items={data}
        itemsPerPageSelect
        itemsPerPage={10}
        pagination
        sorterValue={{ column: 'name', state: 'asc' }}
        noItemsLabel="Nenhum resultado encontrado"
        itemsPerPageLabel="Items por página:"
        onRowClick={handleRowClick}
      />
    </div>
  )
}

UserTable.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
}

export default UserTable
