import * as jose from 'jose'
import moment from 'moment'
import invariant from 'invariant'

class Authentication {
  static getItemName() {
    return `${process.env.REACT_APP_NAME}-manager-token`
  }

  static getToken() {
    return localStorage.getItem(this.getItemName())
  }

  static getTokenInfo() {
    try {
      const token = this.getToken()

      invariant(token, 'No token stored.')

      const payload = jose.decodeJwt(token)

      invariant(this.isValid(payload), 'Invalid Token.')

      return payload.data
    } catch (e) {
      console.info('Authentication.getTokenInfo', e.message)
      return null
    }
  }

  static setToken(token) {
    if ([undefined, null, false, ''].includes(token)) {
      localStorage.removeItem(this.getItemName())
    } else {
      localStorage.setItem(this.getItemName(), token)
    }

    return this.getTokenInfo()
  }

  static isValid(payload) {
    try {
      invariant(payload?.data?.roles === 'admin', 'Insuficient permissions.')
      invariant(payload?.exp, 'Invalid expiration date.')

      const expired = moment.unix(payload.exp).utc() < moment().utc()

      invariant(!expired, 'Expired.')

      return true
    } catch (e) {
      console.info('Authentication.isValid:', e.message)
      return false
    }
  }

  static isAuthenticated() {
    return !!this.getTokenInfo()
  }
}

export default Authentication
