import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CButton, CCardBody, CCol, CCollapse, CImage, CLoadingButton, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react-pro'
import Loading from 'components/Loading'
import Error from 'components/Error'
import ReportDeleteButton from 'components/Report/ReportDeleteButton'

import noPicture from 'assets/images/no-picture.jpg'
import { PowerBIEmbed } from 'powerbi-client-react'
import ReportUserTable from './ReportUserTable'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const response = await axios({
    method: 'POST',
    url: '/powerbi/reports/aad',
    data: { report_id: id },
  })

  return response.data
}

function ReportDetail(props) {
  const { visible, data } = props
  const [activeKey, setActiveKey] = useState(1)
  const { isError, isSuccess, isLoading, data: embed } = useQuery(['report-embed', data.report_id], getData, { staleTime: 60000, enabled: visible })

  return (
    <CCollapse visible={visible}>
      <CCardBody>
        <CRow>
          <CCol md="2" className="text-center">
            <CImage rounded className="w-75 m-2" alt={data.name} src={data.file_url || noPicture} />
          </CCol>
          <CCol md="10">
            <h4>{data.name}</h4>
            <p className="text-muted">{data.reportName}</p>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink active={activeKey === 1} onClick={() => setActiveKey(1)}>
                  Detalhes
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink active={activeKey === 2} onClick={() => setActiveKey(2)}>
                  Usuários
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                <CRow>
                  <CCol md="6">
                    <dl>
                      <dt>Card ID</dt>
                      <dd>{data.id}</dd>
                      <dt>Report ID</dt>
                      <dd>{data.report_id}</dd>
                      <dt>Dataset ID</dt>
                      <dd>{data.dataset_id}</dd>
                      <dt>Group ID</dt>
                      <dd>{data.group_id}</dd>
                    </dl>
                  </CCol>
                  <CCol md="6" className="d-flex flex-column justify-content-center pb-3">
                    <h6 className="w-75 ms-auto text-center">Preview</h6>
                    <div className="w-75 d-flex flex-grow-1 ms-auto my-0 justify-content-center">
                      {isLoading && <Loading />}
                      {isError && <Error className="m-3" />}
                      {isSuccess && (
                        <PowerBIEmbed
                          cssClassName="flex-grow-1 border border-1 rounded-3 p-1 bg-white"
                          embedConfig={{
                            type: 'report',
                            tokenType: 0,
                            id: embed.reportId,
                            embedUrl: embed.embedUrl,
                            accessToken: embed.accessToken,
                            settings: {
                              panes: {
                                filters: {
                                  visible: false,
                                },
                                pageNavigation: {
                                  visible: false,
                                },
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                <ReportUserTable data={data.users} />
              </CTabPane>
            </CTabContent>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12" className="text-center pt-3">
            <CButton size="sm" color="primary" className="me-1" component={Link} to={`/reports/${data.id}`}>
              Editar Relatório
            </CButton>
            <CLoadingButton
              disabled={!embed}
              loading={!embed}
              size="sm"
              color="warning"
              className="me-1"
              component={Link}
              to={`/reports/embed?reportId=${embed?.reportId}&accessToken=${embed?.accessToken}&embedUrl=${encodeURI(embed?.embedUrl)}`}
            >
              Visualizar
            </CLoadingButton>
            <ReportDeleteButton data={data} />
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  )
}

ReportDetail.propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool,
}

export default ReportDetail
