import React from 'react'
import AppContent from 'components/Layout/AppContent'
import AppSidebar from 'components/Layout/AppSidebar'
import AppFooter from 'components/Layout/AppFooter'
import AppHeader from 'components/Layout/AppHeader'
import RestrictedContent from 'components/RestrictedContent'

function DefaultLayout() {
  return (
    <RestrictedContent authenticated to="/login">
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent lg />
        </div>
        <AppFooter />
      </div>
    </RestrictedContent>
  )
}

export default DefaultLayout
