import React, { useContext } from 'react'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CInputGroup, CInputGroupText, CLoadingButton, CRow } from '@coreui/react-pro'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { Formik, Field, ErrorMessage } from 'formik'
import { createError } from 'components/Notifications'
import RestrictedContent from 'components/RestrictedContent'
import FormikErrorParser from 'helpers/FormikErrorParser'
import GlobalContext from 'context/GlobalContext'

import { logo } from 'assets/brand/logo'

function Login() {
  const { setToken, addToast } = useContext(GlobalContext)
  const initialValues = { email: '', password: '' }

  async function handleSubmit(data, { setSubmitting, setErrors }) {
    try {
      setSubmitting(true)

      const response = await axios({
        method: 'POST',
        url: '/users/login',
        data,
      })

      setToken(response?.data?.token)
    } catch (e) {
      if (e.name === 'AxiosError') {
        switch (e.response?.status) {
          case 500:
          case 422:
            setErrors(FormikErrorParser.from(e))
            break
          case 404:
            addToast(createError(e.response.data.errors?.message))
            break
          default:
            addToast(createError('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.'))
            break
        }
      } else {
        addToast(createError(e.message))
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <RestrictedContent anonymous to="/">
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center login-background">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup className="h-100">
                <CCard className="d-flex justify-content-center bg-primary p-4">
                  <CIcon className="sidebar-brand-full" icon={logo} height={35} />
                </CCard>
                <CCard className="p-4">
                  <CCardBody>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ errors, touched, isSubmitting, handleSubmit, handleReset }) => (
                        <CForm onSubmit={handleSubmit} onReset={handleReset}>
                          <h1>Identifique-se</h1>
                          <p className="text-medium-emphasis">Acesso restrito a usuários autorizados.</p>
                          <CInputGroup className="mb-3">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <Field name="email" type="text" placeholder="E-mail" autoComplete="username" className={`form-control ${!!touched.email && !!errors.email && 'is-invalid'}`} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <Field
                              name="password"
                              type="password"
                              placeholder="Senha"
                              autoComplete="current-password"
                              className={`form-control ${!!touched.password && !!errors.password && 'is-invalid'}`}
                            />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CRow>
                            <CCol xs={6}>
                              <CLoadingButton loading={isSubmitting} color="primary" className="px-4" type="submit" disabled={isSubmitting}>
                                Acessar
                              </CLoadingButton>
                            </CCol>
                            <CCol xs={6} className="text-end">
                              <CButton component={Link} color="link" className="px-0" to="/forgot">
                                Esqueceu a senha?
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      )}
                    </Formik>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </RestrictedContent>
  )
}

export default Login
