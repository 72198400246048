import React, { useContext } from 'react'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { Link, useNavigate } from 'react-router-dom'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CInputGroup, CInputGroupText, CLoadingButton, CRow } from '@coreui/react-pro'
import { cilLockLocked } from '@coreui/icons'
import { Formik, Field, ErrorMessage } from 'formik'
import { createError, createSuccess } from 'components/Notifications'
import RestrictedContent from 'components/RestrictedContent'
import FormikErrorParser from 'helpers/FormikErrorParser'
import GlobalContext from 'context/GlobalContext'

import { logo } from 'assets/brand/logo'

const Password = () => {
  const { addToast } = useContext(GlobalContext)
  const initialValues = { current_password: '', password: '', password_confirmation: '' }
  const navigate = useNavigate()

  async function handleSubmit(data, { setSubmitting, setErrors }) {
    try {
      await axios({
        method: 'PUT',
        url: '/users/update-password',
        data,
      })

      addToast(createSuccess('Nova senha definida com sucesso.'))
      navigate('/')
    } catch (e) {
      if (e.name === 'AxiosError') {
        switch (e.response.status) {
          case 500:
          case 422:
            setErrors(FormikErrorParser.from(e))
            break
          case 404:
            addToast(createError(e.response.data.errors?.message))
            break
          default:
            addToast(createError('Erro ao submeter as informações ao servidor. Por favor, tente novamente.'))
            break
        }
      } else {
        addToast(createError(e.message))
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <RestrictedContent authenticated to="/">
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center login-background">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="d-flex justify-content-center bg-primary p-4">
                  <CIcon className="sidebar-brand-full" icon={logo} height={35} />
                </CCard>
                <CCard className="p-4">
                  <CCardBody>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ errors, touched, isSubmitting, handleSubmit, handleReset }) => (
                        <CForm onSubmit={handleSubmit} onReset={handleReset}>
                          <h1>Trocar Senha</h1>
                          <p className="text-medium-emphasis">Preencha os campos para trocar a senha.</p>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <Field
                              required
                              name="current_password"
                              type="password"
                              placeholder="Senha atual"
                              autoComplete="password"
                              className={`form-control ${!!touched.current_password && !!errors.current_password && 'is-invalid'}`}
                            />
                            <ErrorMessage name="current_password" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <Field
                              required
                              name="password"
                              type="password"
                              placeholder="Nova Senha"
                              autoComplete="off"
                              className={`form-control ${!!touched.password && !!errors.password && 'is-invalid'}`}
                            />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <Field
                              required
                              name="password_confirmation"
                              type="password"
                              placeholder="Confirmar Nova Senha"
                              autoComplete="off"
                              className={`form-control ${!!touched.password_confirmation && !!errors.password_confirmation && 'is-invalid'}`}
                            />
                            <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CRow>
                            <CCol xs={6}>
                              <CLoadingButton color="primary" className="px-4" type="submit" disabled={isSubmitting} loading={isSubmitting}>
                                Redefinir
                              </CLoadingButton>
                            </CCol>
                            <CCol xs={6} className="text-end">
                              <CButton component={Link} color="link" className="px-0" to="/login">
                                Voltar ao Login
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      )}
                    </Formik>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </RestrictedContent>
  )
}

export default Password
