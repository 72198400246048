import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { PowerBIEmbed } from 'powerbi-client-react'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'

function ReportEmbed() {
  const [searchParams] = useSearchParams()
  const { id, embedUrl, accessToken } = Object.fromEntries([...searchParams])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Relatório</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <PowerBIEmbed
              cssClassName="embed"
              embedConfig={{
                type: 'report',
                tokenType: 0,
                id,
                embedUrl,
                accessToken,
                settings: {
                  panes: {
                    filters: {
                      visible: false,
                    },
                    pageNavigation: {
                      visible: false,
                    },
                  },
                },
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default ReportEmbed
