const Enums = {
  roles: {
    user: 'Representante',
    admin: 'Administrador',
  },
  states: {
    AC: 'Acre',
    AM: 'Amazonas',
    AP: 'Amapá',
    BA: 'Bahia',
    AL: 'Alagoas',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espirito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MG: 'Minas Gerais',
    MS: 'Mato Grosso do Sul',
    MT: 'Mato Grosso',
    PA: 'Pará',
    PB: 'Paraíba',
    PE: 'Pernambuco',
    PI: 'Piauí',
    PR: 'Paraná',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RO: 'Rondônia',
    RR: 'Roraima',
    RS: 'Rio Grande do Sul',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins',
  },
}

export default Enums
