import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'context/GlobalContext'
import Loading from 'components/Loading'
import Notifications from 'components/Notifications'
import DefaultLayout from 'layout/DefaultLayout'
import Login from 'views/login/Login'
import Forgot from 'views/login/Forgot'
import Password from 'views/login/Password'
import Reset from 'views/login/Reset'
import NotFound from 'views/error/NotFound'
import Unavailable from 'views/error/Unavailable'

import './scss/style.scss'

function App() {
  return (
    <Provider>
      <Notifications />
      <BrowserRouter>
        <Suspense fallback={Loading()}>
          <Routes>
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route exact path="/forgot" name="Recuperar Senha" element={<Forgot />} />
            <Route exact path="/password" name="Recuperar Senha" element={<Password />} />
            <Route exact path="/reset/:uuid" name="Redefinir Senha" element={<Reset />} />
            <Route exact path="/not-found" name="Não encontrado" element={<NotFound />} />
            <Route exact path="/unavailable" name="Indisponível" element={<Unavailable />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
