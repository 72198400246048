import React, { useState } from 'react'
import axios from 'axios'
import { CCol, CRow, CCard, CCardHeader, CCardBody, CSmartTable, CImage } from '@coreui/react-pro'
import { useQuery } from '@tanstack/react-query'
import Error from 'components/Error'
import Loading from 'components/Loading'
import ReportDetail from 'components/Report/ReportDetail'

import noPicture from 'assets/images/no-picture.jpg'

async function getData() {
  const [responseCardsList, responseReportsList] = await Promise.all([
    axios({
      method: 'POST',
      url: '/report-cards/list-by-firm',
      data: { firm_id: process.env.REACT_APP_API_FIRM_ID },
    }),
    axios({
      method: 'GET',
      url: `/powerbi/groups/${process.env.REACT_APP_API_REPORT_GROUP}/reports`,
    }),
  ])

  return responseCardsList.data.map((card) => {
    const matching = (responseReportsList.data || []).find((report) => report.id === card.report_id)

    if (matching) {
      card.reportName = matching.name
    }

    if (card.users?.length > 0) {
      card.users = card.users.map((user) => {
        user.company = user.company || 'N/D'
        user.position = user.position || 'N/D'
        user.regional = user.regional || 'N/D'
        user.state = user.state || 'N/D'

        return user
      })
    }

    return card
  })
}

function ReportList() {
  const [details, setDetails] = useState([])
  const { isError, isSuccess, isLoading, data } = useQuery(['reports'], getData, { staleTime: 60000 })

  function handleRowClick(data, row, field, e) {
    if (true !== e) {
      setDetails((prevState) => {
        const newState = prevState.slice()
        const { id } = data

        newState.includes(id) ? newState.splice(newState.indexOf(id), 1) : newState.push(id)

        return newState
      })
    }
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Listagem</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            {isLoading && <Loading />}
            {isError && <Error />}
            {isSuccess && (
              <CSmartTable
                tableProps={{ striped: true, hover: true }}
                columns={[
                  {
                    key: 'file_url',
                    label: 'Card',
                    _style: { width: '70px' },
                  },
                  {
                    key: 'name',
                    label: 'Nome',
                    _classes: 'align-middle',
                  },
                  {
                    key: 'users',
                    label: 'Usuários',
                    _classes: 'align-middle',
                    _style: { width: '100px' },
                  },
                ]}
                scopedColumns={{
                  file_url: (item) => (
                    <td className="pe-3">
                      <CImage rounded src={item.file_url || noPicture} className="w-100" />
                    </td>
                  ),
                  name: (item) => <td className="align-middle">{item.name}</td>,
                  users: (item) => <td className="align-middle">{(item.users || []).length}</td>,
                  subscriptions: (item) => <td className="align-middle">{(item.subscriptions || []).length}</td>,
                  details: (item) => <ReportDetail data={item} visible={details.includes(item.id)} />,
                }}
                items={data}
                sorterValue={{ column: 'name', state: 'asc' }}
                noItemsLabel="Nenhum resultado encontrado"
                clickableRows
                onRowClick={handleRowClick}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default ReportList
