import React from 'react'
import { CMultiSelect } from '@coreui/react-pro'

function DropdownFilter(values, onChange, replacement) {
  const options = [...new Set(values)]
    .filter((element) => ![null, undefined, ''].includes(element))
    .sort()
    .map((element) => {
      return {
        value: element.toLowerCase(),
        text: replacement ? replacement[element] : element,
      }
    })

  function handleChange(selectedOptions) {
    const selectedValues = selectedOptions.map((selectedOption) => selectedOption.value)

    onChange((item) => (Array.isArray(selectedValues) && selectedValues.length ? selectedValues.includes(item.toLowerCase()) : true))
  }

  return <CMultiSelect size="sm" onChange={handleChange} options={options} placeholder="Selecione..." />
}

export { DropdownFilter }
