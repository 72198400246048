import React from 'react'
import axios from 'axios'
import { CCol, CRow, CCard, CCardHeader, CCardBody } from '@coreui/react-pro'
import { useQuery } from '@tanstack/react-query'
import Error from 'components/Error'
import Loading from 'components/Loading'
import ReportCard from 'components/Dashboard/ReportCard'

async function getData() {
  const [responseCardsList, responseReportsList] = await Promise.all([
    axios({
      method: 'POST',
      url: '/report-cards/list-by-firm',
      data: { firm_id: process.env.REACT_APP_API_FIRM_ID },
    }),
    axios({
      method: 'GET',
      url: `/powerbi/groups/${process.env.REACT_APP_API_REPORT_GROUP}/reports`,
    }),
  ])

  return responseCardsList.data.map((card) => {
    const matching = (responseReportsList.data || []).find((report) => report.id === card.report_id)

    if (matching) {
      card.reportName = matching.name
      card.subscriptions = matching.subscriptions
      card.users = matching.users
    }

    return card
  })
}

function Dashboard() {
  const { isError, isSuccess, isLoading, data } = useQuery(['reports'], getData, { staleTime: 60000 })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Bem-vindo!</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p>Utilize o menu à esquerda para gerencias usuários e relatórios ou acesse os relatórios utilizando os atalhos abaixo.</p>
            {isLoading && <Loading />}
            {isError && <Error />}
            {isSuccess && (
              <div className="grid">
                {data.map((report) => (
                  <ReportCard key={`report-card-${report.report_id}`} id={report.report_id} text={report.name} src={report.file_url} />
                ))}
              </div>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Dashboard
