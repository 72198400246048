import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { CButton, CCardBody, CCardFooter, CCol, CFormInput, CFormSwitch, CImage, CRow, CSmartTable } from '@coreui/react-pro'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createError } from 'components/Notifications'
import Loading from 'components/Loading'
import Error from 'components/Error'
import GlobalContext from 'context/GlobalContext'

async function sendData({ action, relationshipId, report: data }) {
  switch (action) {
    case 'create':
      await axios({ method: 'POST', url: '/reports/store', data })
      break
    case 'update':
      await axios({ method: 'PUT', url: `/reports/${relationshipId}/update`, data })
      break
    case 'delete':
      await axios({ method: 'DELETE', url: `/reports/${relationshipId}` })
      break
    default:
      throw Error('Não foi possível executar a ação.')
  }
}

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const [responseReportsList, responseUserReports] = await Promise.all([
    axios({
      method: 'POST',
      url: '/report-cards/list-by-firm',
      data: { firm_id: process.env.REACT_APP_API_FIRM_ID },
    }),
    axios({
      method: 'POST',
      url: `/reports/list-by-user-with-card`,
      data: {
        user_id: id,
      },
    }),
  ])

  return responseReportsList.data.map((report) => {
    const matching = (responseUserReports.data || []).find((userReport) => userReport.report_id === report.report_id)

    if (matching) {
      report.isEnabled = true
      report.roles = matching.roles
      report.relationshipId = matching.id
    } else {
      report.isEnabled = false
      report.roles = ''
    }

    return report
  })
}

function UserReportButtons({ item, onChange, onSwitch, disabled }) {
  const [value, setValue] = useState(item.roles || '')

  function handleChange(...args) {
    setValue(args[0])
    onChange && onChange(...args)
  }

  function handleSwitch(...args) {
    setValue('')
    onSwitch && onSwitch(...args)
  }

  return (
    <td className="align-middle text-center">
      <CRow>
        <CCol xs="9">
          <CFormInput size="sm" value={value} disabled={disabled || !item.isEnabled} onChange={(e) => handleChange(e.target.value, item)} />
        </CCol>
        <CCol xs="2">
          <CFormSwitch size="xl" defaultChecked={item.isEnabled} disabled={disabled} onChange={(e) => handleSwitch(e.target.checked, item)} />
        </CCol>
      </CRow>
    </td>
  )
}

UserReportButtons.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onSwitch: PropTypes.func,
  disabled: PropTypes.bool,
}

function UserReport({ user }) {
  const { addToast } = useContext(GlobalContext)
  const { isError, isSuccess, isLoading, isRefetching, data, refetch } = useQuery(['reports-with-card', user.id], getData, { staleTime: 60000 })
  const { isLoading: isUpdating, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })
  const queryClient = useQueryClient()

  let typing

  function handleSwitch(enabled, { relationshipId, name, roles, dataset_id, report_id, group_id }) {
    enabled ? mutate({ action: 'create', report: { name, roles, dataset_id, report_id, group_id, user_id: user.id } }) : mutate({ action: 'delete', relationshipId })
  }

  function handleChange(value, { relationshipId, name, dataset_id, report_id, group_id }) {
    clearTimeout(typing)
    typing = setTimeout(() => mutate({ action: 'update', relationshipId, report: { name, roles: value, dataset_id, report_id, group_id } }), 800)
  }

  function handleSuccess() {
    queryClient.invalidateQueries({ queryKey: ['reports-with-card', user.id] })
    queryClient.invalidateQueries({ queryKey: ['user', user.id] })
  }

  function handleError(e) {
    refetch()
    addToast(createError(e.message))
  }

  return (
    <>
      <CCardBody>
        {isLoading && <Loading />}
        {isError && <Error className="m-3" />}
        {isSuccess && (
          <CSmartTable
            tableProps={{ striped: true, hover: true }}
            columns={[
              {
                key: 'file_url',
                label: 'Card',
                _style: { width: '70px' },
              },
              {
                key: 'name',
                label: 'Nome',
                _classes: 'align-middle',
              },
              {
                key: 'configuration',
                label: `Configurações de ${user.name}`,
                _style: { width: '300px' },
              },
            ]}
            scopedColumns={{
              file_url: (item) => (
                <td className="pe-3">
                  <CImage rounded src={item.file_url} className="w-100" />
                </td>
              ),
              name: (item) => <td className="align-middle">{item.name}</td>,
              configuration: (item) => <UserReportButtons item={item} disabled={isUpdating || isRefetching} onChange={handleChange} onSwitch={handleSwitch} />,
            }}
            items={data}
            sorterValue={{ column: 'name', state: 'asc' }}
            noItemsLabel="Nenhum resultado encontrado"
          />
        )}
      </CCardBody>
      <CCardFooter>
        <CButton component={Link} color="link" className="float-start my-2" to={`/users`}>
          Voltar
        </CButton>
      </CCardFooter>
    </>
  )
}

UserReport.propTypes = {
  user: PropTypes.object,
}

export default UserReport
