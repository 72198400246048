import React, { useState } from 'react'
import PropType from 'prop-types'
import { CButton, CLoadingButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro'

function ConfirmModal({ buttonText, confirmText, cancelText, title, text, color, onConfirm, onCancel, ...props }) {
  const [visible, setVisible] = useState()

  function handleClick() {
    setVisible(true)
  }

  function handleCancel() {
    setVisible(false)
    onCancel && onCancel()
  }

  function handleConfirm() {
    setVisible(false)
    onConfirm && onConfirm()
  }

  return (
    <>
      <CLoadingButton color={color} {...props} onClick={handleClick}>
        {buttonText}
      </CLoadingButton>
      <CModal alignment="center" visible={visible} onClose={handleCancel}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{text}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleCancel}>
            {cancelText}
          </CButton>
          <CButton color={color} onClick={handleConfirm}>
            {confirmText}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

ConfirmModal.propTypes = {
  isLoading: PropType.bool,
  onConfirm: PropType.func,
  onCancel: PropType.func,
  buttonText: PropType.string,
  confirmText: PropType.string,
  cancelText: PropType.string,
  title: PropType.string,
  text: PropType.string,
  color: PropType.string,
}

export default ConfirmModal
