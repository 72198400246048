import React, { useContext, useEffect } from 'react'
import { CButtonGroup, CFormCheck, CContainer, CHeader, CHeaderBrand, CHeaderDivider, CHeaderNav, CHeaderToggler, CLink, CButton } from '@coreui/react-pro'
import { cilLink, cilMenu, cilMoon, cilSun } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import AppBreadcrumb from 'components/Layout/AppBreadcrumb'
import AppHeaderDropdown from 'components/Layout/AppHeaderDropdown'
import UserContext from 'context/GlobalContext'

import { logo } from 'assets/brand/logo'
import { logoNegative } from 'assets/brand/logo-negative'
import Authentication from 'helpers/Authentication'

function AppHeader() {
  const { sidebarShow, setSidebarShow, theme, setTheme } = useContext(UserContext)

  useEffect(() => {
    if (theme === 'light') {
      document.body.classList.remove('dark-theme')
    } else {
      document.body.classList.add('dark-theme')
    }
  }, [theme])

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => setSidebarShow(!sidebarShow)}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={theme === 'light' ? logo : logoNegative} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="ms-auto me-4">
          <CButton component={CLink} color="warning" className="me-2" href={`${process.env.REACT_APP_SITE_URL}/sso?token=${Authentication.getToken()}`} target="_blank">
            <CIcon icon={cilLink}></CIcon> Acessar o Portal
          </CButton>
          <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === 'light'}
              onChange={() => setTheme('light')}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === 'dark'}
              onChange={() => setTheme('dark')}
            />
          </CButtonGroup>
        </CHeaderNav>
        <CHeaderNav className="ms-3 me-4">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
