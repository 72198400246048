import React, { useState, useContext } from 'react'
import CIcon from '@coreui/icons-react'
import SimpleBar from 'simplebar-react'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro'
import AppSidebarNav from 'components/Layout/AppSidebarNav'
import UserContext from 'context/GlobalContext'

import navigation from 'misc/menu'

import { logo } from 'assets/brand/logo'
import { logoNegative } from 'assets/brand/logo-negative'
import { sygnet } from 'assets/brand/sygnet'
import 'simplebar/dist/simplebar.min.css'
import { useNavigate } from 'react-router-dom'

function AppSidebar() {
  const { theme, sidebarShow } = useContext(UserContext)
  const [unfoldable, setUnfoldable] = useState(false)
  const navigate = useNavigate()

  return (
    <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarShow}>
      <CSidebarBrand className="d-none d-md-flex" onClick={() => navigate('/')}>
        <CIcon className="sidebar-brand-full" icon={theme === 'light' ? logo : logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler className="d-none d-lg-flex" onClick={() => setUnfoldable(!unfoldable)} />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
