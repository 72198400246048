import React, { useContext } from 'react'
import PropType from 'prop-types'
import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createError, createSuccess } from 'components/Notifications'
import GlobalContext from 'context/GlobalContext'
import ConfirmModal from 'components/ConfirmModal'

async function sendData(id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/banners/${id}`,
    })
  } catch (e) {
    if (e.name === 'AxiosError') {
      switch (e.response?.status) {
        case 500:
        case 422:
          throw Error('Erro ao excluir o banner. Por favor, tente novamente.')
        case 404:
          throw Error('Banner não encontrado.')
        default:
          throw Error('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.')
      }
    } else {
      throw e
    }
  }
}

function BannerDeleteButton({ data, onSuccess, ...props }) {
  const { addToast } = useContext(GlobalContext)
  const { isLoading, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })
  const queryClient = useQueryClient()

  function handleClick() {
    mutate(data.id)
  }

  function handleSuccess() {
    queryClient.invalidateQueries({ queryKey: ['banner', data.id] })
    queryClient.invalidateQueries({ queryKey: ['banners'] })

    addToast(createSuccess('Banner excluído com sucesso'))

    onSuccess && onSuccess()
  }

  function handleError(e) {
    addToast(createError(e.message))
  }

  return (
    <ConfirmModal
      size="sm"
      loading={isLoading}
      disabled={isLoading}
      color="danger"
      title="Excluir Banner"
      text={`Tem certeza que deseja excluir o banner?`}
      buttonText="Excluir"
      confirmText="Sim"
      cancelText="Não"
      onConfirm={handleClick}
      {...props}
    />
  )
}

BannerDeleteButton.propTypes = {
  data: PropType.object,
  onSuccess: PropType.func,
}

export default BannerDeleteButton
