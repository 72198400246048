class FormikErrorParser {
  static from(e) {
    return e?.response?.data?.errors?.path.reduce((errors, field) => {
      errors[field] = e.response.data.errors.message

      return errors
    }, {})
  }
}

export default FormikErrorParser
