import React, { useContext } from 'react'
import { CAvatar, CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import { cilLockLocked, cilUser, cilFingerprint } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import genericAvatar from 'assets/images/avatars/generic.svg'
import UserContext from 'context/GlobalContext'
import { Link } from 'react-router-dom'

function AppHeaderDropdown() {
  const { user, setToken } = useContext(UserContext)

  function handleLogoff() {
    setToken(null)
  }

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={user?.file_url || genericAvatar} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">{user?.name}</CDropdownHeader>
        <Link className="dropdown-item" to={`/users/${user?.id}`}>
          <CIcon icon={cilUser} className="me-2" />
          Meus Dados
        </Link>
        <Link className="dropdown-item" to={`/password`}>
          <CIcon icon={cilFingerprint} className="me-2" />
          Trocar Senha
        </Link>
        <CDropdownDivider />
        <CDropdownItem href="#" onClick={handleLogoff}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Sair
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
