export const logo = [
  '308 166',
  `
  <path d="M7.76886 63.0888C7.49101 90.6912 27.6697 113.857 55.3762 117.755C73.4363 120.291 89.2909 114.499 102.471 101.822C104.234 100.129 105.337 99.5736 107.351 101.614C118.699 113.127 130.204 124.484 141.613 135.937C145.911 140.252 148.724 145.01 147.804 151.644C146.979 157.618 143.888 161.655 138.713 163.791C133.087 166.118 127.035 165.91 122.424 161.568C113.16 152.859 104.338 143.682 95.2994 134.73C92.8422 132.29 95.829 131.639 96.6973 130.38C97.965 128.548 99.3108 128.14 101.195 130.067C108.879 137.925 116.702 145.635 124.473 153.398C125.698 154.622 126.896 155.846 128.493 156.654C132.21 158.521 136.308 157.835 138.661 154.839C141.353 151.427 141.544 147.129 138.609 144.142C127.886 133.245 116.989 122.513 106.231 111.651C104.347 109.75 103.166 111.096 101.855 112.164C94.4311 118.189 85.9394 121.992 76.6836 124.154C52.6325 129.789 26.9143 120.195 12.4488 100.225C-6.67051 73.8293 -3.59682 38.5688 20.4977 16.6536C43.4896 -4.25442 76.5447 -5.55683 101.204 13.2065C118.144 26.0917 126.158 43.7784 125.793 65.0077C125.654 73.0826 123.935 80.9839 120.384 88.4076C119.186 90.9082 117.892 91.516 115.33 90.2223C113.021 89.0501 112.335 88.1992 113.472 85.3686C121.842 64.5214 119.654 45.0113 105.198 27.4028C86.6427 4.81034 53.8307 1.53695 31.0559 17.6781C15.6615 28.5923 7.74281 43.9694 7.76018 63.0888H7.76886Z" fill="#FFCD00"/>
  <path d="M91.3657 59.7631C91.3657 66.2664 91.3743 72.7785 91.3657 79.2818C91.3657 80.9315 91.93 82.8504 88.9953 82.7202C86.3731 82.6073 86.9114 80.8708 86.9114 79.386C86.8941 66.9524 86.9114 54.5187 86.8854 42.085C86.8854 40.7913 86.9027 39.4455 86.5641 38.2212C86.0779 36.45 84.5671 35.7119 82.8479 35.955C80.9117 36.2329 79.7916 37.5874 79.7482 39.5584C79.67 43.7521 79.6874 47.9459 79.6874 52.1309C79.6874 59.7978 79.7308 67.4559 79.67 75.1228C79.6179 81.2267 76.5529 84.8127 71.5517 84.8301C66.5418 84.8474 63.4768 81.2267 63.4681 75.2009C63.4507 68.2634 63.442 61.3173 63.4681 54.3798C63.4768 51.7836 62.8342 49.5869 59.7866 49.6043C56.7737 49.6303 56.0964 51.8184 56.1051 54.4145C56.1312 61.4996 56.1485 68.5847 56.1051 75.6698C56.0704 81.1747 52.7449 84.8474 47.926 84.8214C43.0463 84.7953 39.9987 81.2615 39.9379 75.5309C39.9119 72.9261 39.8945 70.3213 39.9379 67.7251C39.9813 65.2332 39.0783 63.3056 36.4388 63.1406C33.4259 62.9583 32.5142 65.1377 32.4621 67.6991C32.3839 71.7452 32.436 75.7914 32.436 79.8462C32.436 81.2962 32.6878 82.6854 30.3782 82.7375C28.0599 82.7896 27.8342 81.6609 27.8776 79.8201C27.9644 75.6264 27.7821 71.4326 27.9471 67.2476C28.1468 62.1161 31.7327 58.6256 36.4127 58.7211C41.2316 58.8166 44.2272 62.1855 44.2966 67.647C44.3314 70.3907 44.3314 73.1431 44.2966 75.8869C44.2619 78.4135 45.1823 80.2196 47.926 80.2543C50.6784 80.289 51.6596 78.4743 51.6596 75.9737C51.6683 68.8886 51.6509 61.8035 51.6769 54.7184C51.703 48.6144 54.6638 45.15 59.8127 45.1587C64.9181 45.1674 67.8876 48.6665 67.9136 54.7705C67.9397 61.5691 67.9744 68.359 67.8963 75.1575C67.8615 77.9186 68.3478 80.3237 71.6733 80.2282C74.8859 80.1327 75.4416 77.6842 75.4416 74.9839C75.4155 63.5661 75.4155 52.1396 75.4416 40.7219C75.4589 34.8871 78.385 31.5008 83.3255 31.4748C88.1878 31.4487 91.3049 34.9652 91.357 40.7045C91.4178 47.0689 91.3743 53.4247 91.3743 59.7891L91.3657 59.7631Z" fill="white"/>
  <path d="M188.361 64.001C188.213 66.8924 185.747 69.4017 182.839 69.4017C179.93 69.4017 177.464 66.8924 177.316 64.001V48.0596H171.091V64.001C171.412 70.2787 176.596 75.358 182.839 75.358C189.081 75.358 194.256 70.27 194.586 64.001V48.0596H188.369V64.001H188.361Z" fill="white"/>
  <path d="M146.137 21.0817C146.293 18.1904 148.75 15.6811 151.668 15.6811C154.585 15.6811 157.042 18.1904 157.199 21.0817V37.0232H163.415V21.0817C163.085 14.8128 157.919 9.7334 151.668 9.7334C145.416 9.7334 140.241 14.8128 139.92 21.0817V37.0232H146.137V21.0817Z" fill="white"/>
  <path d="M194.083 24.0421L188.508 23.3475C188.508 27.8277 185.27 31.8218 180.381 31.7784C175.484 31.7437 172.089 27.8277 172.089 23.3648C172.089 18.9019 175.78 15.2638 180.303 15.2638C181.944 15.2638 183.672 15.7327 184.983 16.7573L172.419 23.7469L174.859 28.7394L193.232 18.6241C191.27 13.4665 186.216 9.77637 180.312 9.77637C172.715 9.77637 166.515 15.889 166.515 23.3822C166.515 30.8754 172.715 36.9793 180.312 36.9793C187.909 36.9793 193.744 31.2227 194.1 24.0421" fill="white"/>
  <path d="M203.564 39.3936L198.91 46.1834H204.415L210.745 39.3936H203.564Z" fill="white"/>
  <path d="M167.218 61.3258C167.218 53.9282 161.106 47.9023 153.604 47.9023C146.102 47.9023 139.989 53.9282 139.989 61.3258C139.989 68.7148 146.111 74.7406 153.604 74.7406C156.139 74.7406 158.527 74.046 160.567 72.8478L160.593 63.6441C159.604 66.5094 156.851 68.5759 153.612 68.5759C149.558 68.5759 146.25 65.3199 146.25 61.3258C146.25 57.3318 149.558 54.0671 153.612 54.0671C157.676 54.0671 160.984 57.3318 160.984 61.3258V85.6027H167.236V62.1507H167.21C167.227 61.8728 167.236 61.6037 167.236 61.3258" fill="white"/>
  <path d="M211.031 15.1687C215.607 15.1687 219.358 18.8588 219.358 23.3825C219.358 27.9062 215.607 31.579 211.031 31.579C206.438 31.579 202.705 27.8975 202.705 23.3825C202.705 18.8675 206.438 15.1687 211.031 15.1687ZM211.031 37.0318C218.664 37.0318 224.88 30.9018 224.88 23.3825C224.88 15.8546 218.664 9.72461 211.031 9.72461C203.399 9.72461 197.174 15.8546 197.174 23.3825C197.174 30.9105 203.399 37.0318 211.031 37.0318Z" fill="white"/>
  <path d="M276.89 75.357V69.4354H272.687C268.051 69.4354 264.256 66.0057 264.265 61.699C264.265 57.6703 267.643 54.3708 271.819 53.9714H276.89V48.0498H271.819C264.109 48.4753 257.961 54.4577 257.961 61.699C257.961 68.9404 264.578 75.357 272.696 75.357H276.898H276.89Z" fill="white"/>
  <path d="M308 61.3258C308 53.9282 301.879 47.9023 294.377 47.9023C286.875 47.9023 280.762 53.9282 280.762 61.3258C280.762 68.7148 286.875 74.7406 294.377 74.7406C296.921 74.7406 299.3 74.046 301.34 72.8478L301.358 63.6441C300.368 66.5094 297.615 68.5759 294.377 68.5759C290.322 68.5759 287.014 65.3199 287.014 61.3258C287.014 57.3318 290.322 54.0671 294.377 54.0671C298.44 54.0671 301.748 57.3318 301.748 61.3258V75.3571H308V62.1507H307.974C307.991 61.8728 308 61.6037 308 61.3258Z" fill="white"/>
  <path d="M250.885 46.1755C252.656 46.1755 254.098 44.7602 254.098 43.015C254.098 41.2698 252.656 39.8545 250.885 39.8545C249.114 39.8545 247.681 41.2785 247.681 43.015C247.681 44.7515 249.131 46.1755 250.885 46.1755Z" fill="white"/>
  <path d="M204.858 48.0498H198.45V75.357H204.858V48.0498Z" fill="white"/>
  <path d="M223.065 75.357H229.473V57.349C229.473 55.2044 231.253 53.4418 233.432 53.4418C235.612 53.4418 237.4 55.1957 237.4 57.349V75.357H243.817V57.349C243.817 52.2262 239.571 48.0498 234.379 48.0498C230.958 48.0498 227.936 49.8645 226.278 52.5648C224.611 49.8732 221.598 48.0498 218.177 48.0498C215.329 48.0498 212.768 49.3001 211.04 51.2711C209.607 52.9034 208.739 55.0307 208.739 57.349V75.357H215.147V57.349C215.147 55.2044 216.927 53.4418 219.115 53.4418C221.303 53.4418 223.074 55.1957 223.074 57.349V75.357H223.065Z" fill="white"/>
  <path d="M254.098 48.0498H247.681V75.357H254.098V48.0498Z" fill="white"/>
  <path d="M210.076 107.207H210.024C210.024 107.207 209.677 108.727 209.364 109.638L207.48 114.909H212.655L210.771 109.638C210.449 108.735 210.085 107.207 210.085 107.207H210.076Z" fill="#FECC2F"/>
  <path d="M222.848 98.793H146.588C144.634 98.793 143.045 100.391 143.045 102.336V124.78C143.045 126.734 144.643 128.323 146.588 128.323H222.848C224.802 128.323 226.391 126.725 226.391 124.78V102.336C226.391 100.382 224.793 98.793 222.848 98.793ZM167.305 106.746H166.958C166.515 106.746 166.298 106.842 166.15 107.285L160.706 121.95H158.11L152.666 107.285C152.518 106.842 152.275 106.746 151.858 106.746H151.511V104.636H152.883C154.107 104.636 154.524 104.906 154.915 106.06L158.718 116.731C159.039 117.661 159.378 119.111 159.378 119.111H159.43C159.43 119.111 159.751 117.661 160.09 116.731L163.893 106.06C164.309 104.906 164.7 104.636 165.924 104.636H167.296V106.746H167.305ZM174.347 106.746H172.653V119.84H174.347V121.95H168.633V119.84H170.327V106.746H168.633V104.636H174.347V106.746ZM181.953 122.245C178.271 122.245 176.213 119.666 176.213 119.666L177.663 117.93C177.663 117.93 179.426 120.014 182.005 120.014C183.62 120.014 184.974 119.032 184.974 117.435C184.974 113.805 176.613 114.37 176.613 108.978C176.613 106.382 178.896 104.341 182.152 104.341C183.993 104.341 187.084 105.175 187.084 107.528V108.952H184.827V108.144C184.827 107.241 183.524 106.573 182.152 106.573C180.286 106.573 179.113 107.606 179.113 108.9C179.113 112.407 187.449 111.617 187.449 117.313C187.449 119.961 185.391 122.245 181.953 122.245ZM200.959 106.746H197.477V121.95H195.029V106.746H191.547C191.2 106.746 191.009 106.92 191.009 107.285V108.387H188.829V106.104C188.829 105.001 189.194 104.636 190.297 104.636H202.218C203.321 104.636 203.686 105.001 203.686 106.104V108.387H201.506V107.285C201.506 106.92 201.306 106.746 200.968 106.746H200.959ZM217.96 121.95H216.588C215.364 121.95 214.973 121.655 214.574 120.526L213.271 116.966H206.898L205.596 120.526C205.179 121.655 204.788 121.95 203.564 121.95H202.218V119.84H202.565C203.008 119.84 203.225 119.744 203.373 119.302L208.791 104.636H211.37L216.788 119.302C216.935 119.744 217.178 119.84 217.595 119.84H217.96V121.95Z" fill="#FFCD00"/>
  `,
]
