import React from 'react'
import { CCallout, CLink } from '@coreui/react-pro'

function Error(props) {
  return (
    <CCallout color="danger" {...props}>
      Ocorreu um erro no processamento. Tente&nbsp;
      <CLink color="link" onClick={() => window.location.reload(false)}>
        recarregar
      </CLink>
      &nbsp;a pagina.
    </CCallout>
  )
}

export default Error
