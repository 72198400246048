import React from 'react'
import PropType from 'prop-types'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CImage, CLoadingButton } from '@coreui/react-pro'

import noPicture from 'assets/images/no-picture.jpg'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const response = await axios({
    method: 'POST',
    url: '/powerbi/reports/aad',
    data: { report_id: id.toString() },
  })

  return response.data
}

function ReportCard({ id, text, src, ...props }) {
  const { isLoading, data } = useQuery(['report-embed', id], getData, { staleTime: 60000 })
  const navigate = useNavigate()

  function handleCLick() {
    navigate(`/reports/embed?reportId=${data?.reportId}&accessToken=${data?.accessToken}&embedUrl=${encodeURI(data?.embedUrl)}`)
  }

  return (
    <div className="text-center">
      <CImage rounded alt={text} src={src || noPicture} className="w-100 border" style={{ maxWidth: '100%' }} />
      <CLoadingButton {...props} disabled={isLoading} loading={isLoading} size="sm" color="primary" className="mt-3" onClick={handleCLick}>
        {text}
      </CLoadingButton>
    </div>
  )
}

ReportCard.propTypes = {
  id: PropType.string,
  src: PropType.string,
  text: PropType.string,
}

export default ReportCard
