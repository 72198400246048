import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Formik, Field, ErrorMessage } from 'formik'
import { CButton, CCardBody, CCardFooter, CCol, CForm, CFormFloating, CFormLabel, CFormSelect, CLoadingButton, CRow } from '@coreui/react-pro'
import { createError, createSuccess } from 'components/Notifications'
import FormikErrorParser from 'helpers/FormikErrorParser'
import FormikUploadComponent from 'components/FormikUploadComponent'
import UserResendEmailButton from 'components/User/UserResendEmailButton'
import UserDeleteButton from 'components/User/UserDeleteButton'
import GlobalContext from 'context/GlobalContext'
import OptionParser from 'helpers/OptionParser'
import Enums from 'misc/enums'

async function sendData({ values: { id, file, file_url, reports, slugId, hashUnicom, ...data }, helpers: { setSubmitting, setErrors, setFieldError } }) {
  try {
    if (!id) {
      data = {
        ...data,
        appUrl: data.roles === 'admin' ? process.env.REACT_APP_CMS_URL : process.env.REACT_APP_SITE_URL,
      }
    }

    const response = await axios({
      method: id ? 'PUT' : 'POST',
      url: id ? `/users/${id}/update-auth-user` : '/users/store-auth-user',
      data,
    })

    if ([null, undefined, ''].includes(file)) {
      return
    }

    try {
      await axios({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'PUT',
        url: `/users/${id || response.data.id}/upload`,
        data: { file },
      })
    } catch (e) {
      setFieldError('file', 'Erro ao enviar imagem.')
      throw Error('Confira os campos destacados em vermelho.')
    }
  } catch (e) {
    if (e.name === 'AxiosError') {
      switch (e.response?.status) {
        case 500:
        case 422:
          setErrors(FormikErrorParser.from(e))
          throw Error('Erro de validação. Confira os campos destacados em vermelho.')
        case 400:
        case 404:
          throw Error(e.response.data.errors?.message)
        default:
          throw Error('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.')
      }
    } else {
      throw e
    }
  } finally {
    setSubmitting(false)
  }
}

function UserForm(props) {
  const { user } = props
  const { addToast } = useContext(GlobalContext)
  const { isSuccess, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const initialValues = user || { name: '', email: '', file: '', roles: '', state: '', position: '', company: '', regional: '', firm_id: process.env.REACT_APP_API_FIRM_ID }

  function handleSubmit(values, helpers) {
    mutate({ values, helpers })
  }

  function handleSuccess() {
    queryClient.invalidateQueries({ queryKey: ['users'] })
    addToast(createSuccess(user ? 'Usuário atualizado com sucesso.' : 'Usuário cadastrado com sucesso.'))
    navigate('/users')
  }

  function handleError(e) {
    addToast(createError(e.message))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, touched, isSubmitting, handleSubmit, handleReset }) => (
        <CForm onSubmit={handleSubmit} onReset={handleReset} validated={isSuccess}>
          <CCardBody>
            <CRow>
              <CCol xl="3" xxl="2">
                <Field
                  id="file"
                  name="file"
                  as={FormikUploadComponent}
                  src={user?.file_url}
                  className="d-flex justify-content-center align-items-center border border-2 rounded-6  position-relative"
                  style={{ margin: '0 auto 40px auto', width: '160px', height: '160px' }}
                />
              </CCol>
              <CCol xl="9" xxl="10">
                <CFormFloating className="mb-3">
                  <Field name="name" type="text" placeholder="John Doe" className={`form-control ${!!touched.name && !!errors.name && 'is-invalid'}`} />
                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="name">Nome</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="email" type="email" placeholder="john@example.com" className={`form-control ${!!touched.email && !!errors.email && 'is-invalid'}`} />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="email">E-mail</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="roles">
                    {({ field, meta }) => (
                      <CFormSelect className={`form-control ${!!meta.touched && !!meta.error && 'is-invalid'}`} options={['Selecione...', ...OptionParser.fromEnum(Enums.roles)]} {...field} />
                    )}
                  </Field>
                  <ErrorMessage name="roles" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="roles">Perfil</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="position" type="text" placeholder="Gerente" className={`form-control ${!!touched.position && !!errors.position && 'is-invalid'}`} />
                  <ErrorMessage name="position" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="position">Cargo</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="company" type="text" placeholder="john@example.com" className={`form-control ${!!touched.company && !!errors.company && 'is-invalid'}`} />
                  <ErrorMessage name="company" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="company">Empresa</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="regional" type="text" placeholder="Sudeste" className={`form-control ${!!touched.regional && !!errors.regional && 'is-invalid'}`} />
                  <ErrorMessage name="regional" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="company">Regional</CFormLabel>
                </CFormFloating>

                <CFormFloating className="mb-3">
                  <Field name="state">
                    {({ field, meta }) => (
                      <CFormSelect className={`form-control ${!!meta.touched && !!meta.error && 'is-invalid'}`} options={['Selecione...', ...OptionParser.fromEnum(Enums.states)]} {...field} />
                    )}
                  </Field>
                  <ErrorMessage name="state" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="roles">Estado</CFormLabel>
                </CFormFloating>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="clearfix">
            <CLoadingButton loading={isSubmitting} disabled={isSubmitting} size="lg" color="warning" className="float-end my-3" type="submit">
              Enviar
            </CLoadingButton>
            {user && <UserResendEmailButton size="sm" color="info" className="float-end my-4 me-4" data={user} />}
            {user && <UserDeleteButton size="sm" className="float-end my-4 me-2" data={user} onSuccess={handleSuccess} />}
            <CButton component={Link} color="link" className="float-start my-3" to={`/users`}>
              Voltar
            </CButton>
          </CCardFooter>
        </CForm>
      )}
    </Formik>
  )
}

UserForm.propTypes = {
  user: PropTypes.object,
}

export default UserForm
