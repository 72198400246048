import React from 'react'
import axios from 'axios'
import { CCol, CRow, CCard, CCardHeader, CCardBody } from '@coreui/react-pro'
import { useQuery } from '@tanstack/react-query'
import Error from 'components/Error'
import Loading from 'components/Loading'
import UserTable from 'components/User/UserTable'

async function getData() {
  const response = await axios({
    method: 'POST',
    url: '/users/list-by-firm',
    data: {
      firm_id: process.env.REACT_APP_API_FIRM_ID,
    },
  })

  return response.data.map((item) => {
    item.company = item.company || 'N/D'
    item.position = item.position || 'N/D'
    item.regional = item.regional || 'N/D'
    item.state = item.state || 'N/D'

    return item
  })
}

function UserList() {
  const { isError, isSuccess, isLoading, data, refetch } = useQuery(['users'], getData, { staleTime: 60000 })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Listagem</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            {isError && <Error />}
            {isLoading && <Loading />}
            {isSuccess && <UserTable data={data} onChange={refetch} />}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default UserList
