import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilCloudUpload } from '@coreui/icons'
import { useDropzone } from 'react-dropzone'
import { useField } from 'formik'

function FormikUploadComponent({ label, src, className, style, ...props }) {
  const [preview, setPreview] = useState(src)
  const [ignored, meta, helpers] = useField(props)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: { 'image/jpeg': [], 'image/png': [] }, multiple: false, onDrop })
  const { setValue } = helpers
  const { error } = meta

  function onDrop(acceptedFiles) {
    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onload = function (item) {
        setPreview(item.target.result)
      }

      reader.readAsDataURL(file)
      setValue(file)
    }
  }

  return (
    <div
      {...getRootProps({
        className: `dropzone ${error && 'border-danger'} ${className}`,
        style: { backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url("${preview}")`, ...style },
      })}
    >
      {label && (
        <label className={`text-center pt-2 ps-3 ${preview && 'text-white'}`} style={preview && { textShadow: '1px 1px 2px #000000' }}>
          {label}
        </label>
      )}
      <div className={`text-center p-4 ${preview && 'text-white'}`} style={preview && { textShadow: '1px 1px 2px #000000' }}>
        <CIcon size="xl" icon={cilCloudUpload} className="m-2"></CIcon>
        <p className={error && 'text-danger'}>
          <small>{isDragActive ? 'Solte a imagem aqui...' : error ?? 'Arraste uma imagem ou clique.'}</small>
        </p>
      </div>
      <input {...getInputProps()} />
    </div>
  )
}

FormikUploadComponent.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default FormikUploadComponent
