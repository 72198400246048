import React, { useContext } from 'react'
import PropType from 'prop-types'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { createError, createSuccess } from 'components/Notifications'
import ConfirmModal from 'components/ConfirmModal'
import GlobalContext from 'context/GlobalContext'

async function sendData({ id, email, roles }) {
  try {
    await axios({
      method: 'POST',
      url: '/users/send-mail-store-auth-user',
      data: { id, email, appUrl: roles === 'admin' ? process.env.REACT_APP_CMS_URL : process.env.REACT_APP_SITE_URL },
    })
  } catch (e) {
    if (e.name === 'AxiosError') {
      switch (e.response?.status) {
        case 500:
        case 422:
          throw Error('Erro ao reenviar senha ao usuário. Por favor, tente novamente.')
        case 404:
          throw Error('Usuário não encontrado.')
        default:
          throw Error('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.')
      }
    } else {
      throw e
    }
  }
}

function UserResendEmailButton({ data, ...props }) {
  const { addToast } = useContext(GlobalContext)
  const { isLoading, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })

  function handleClick() {
    mutate(data)
  }

  function handleSuccess() {
    addToast(createSuccess('Senha reenviada com sucesso.'))
  }

  function handleError(e) {
    addToast(createError(e.message))
  }

  return (
    <ConfirmModal
      size="sm"
      loading={isLoading}
      disabled={isLoading}
      color="warning"
      title="Reenviar Senha"
      text={`Tem certeza que deseja alterar e reenviar a senha do usuário "${data.name}" por e-mail?`}
      buttonText="Reenviar Senha"
      confirmText="Sim"
      cancelText="Não"
      onConfirm={handleClick}
      {...props}
    />
  )
}

UserResendEmailButton.propTypes = {
  data: PropType.object,
}

export default UserResendEmailButton
