import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Formik, Field, ErrorMessage } from 'formik'
import { CButton, CCardBody, CCardFooter, CCol, CForm, CFormFloating, CFormLabel, CLoadingButton, CRow } from '@coreui/react-pro'
import { createError, createSuccess } from 'components/Notifications'
import FormikErrorParser from 'helpers/FormikErrorParser'
import FormikUploadComponent from 'components/FormikUploadComponent'
import BannerDeleteButton from 'components/Banner/BannerDeleteButton'
import GlobalContext from 'context/GlobalContext'

async function sendData({ values: { id, firm_id, created_at, updated_at, ordenation, ...data }, helpers: { setSubmitting, setErrors } }) {
  try {
    if (typeof data.desktop_img == 'string') {
      delete data.desktop_img
    }

    if (typeof data.mobile_img == 'string') {
      delete data.mobile_img
    }

    await axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: id ? 'PUT' : 'POST',
      url: id ? `/banners/${id}` : '/banners',
      data,
    })
  } catch (e) {
    if (e.name === 'AxiosError') {
      switch (e.response?.status) {
        case 500:
        case 422:
        case 400:
          const parsedErrors = FormikErrorParser.from(e)

          if (parsedErrors) {
            setErrors(parsedErrors)
            throw Error('Erro de validação. Confira os campos destacados em vermelho.')
          } else {
            throw Error('Não foi possível submeter suas informações. Um erro foi retornado em um formato inesperado. Por favor, informe o ocorrido.')
          }
        case 404:
          throw Error(e.response.data.errors?.message)
        default:
          throw Error('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.')
      }
    } else {
      throw e
    }
  } finally {
    setSubmitting(false)
  }
}

function BannerForm(props) {
  const { banner } = props
  const { addToast } = useContext(GlobalContext)
  const { isSuccess, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const initialValues = banner || { desktop_img: '', mobile_img: '', report_link: '' }

  function handleSubmit(values, helpers) {
    mutate({ values, helpers })
  }

  function handleSuccess() {
    queryClient.invalidateQueries({ queryKey: ['banners'] })
    addToast(createSuccess(banner ? 'Banner atualizado com sucesso.' : 'Banner cadastrado com sucesso.'))
    navigate('/banners')
  }

  function handleError(e) {
    addToast(createError(e.message))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, touched, isSubmitting, handleSubmit, handleReset }) => (
        <CForm onSubmit={handleSubmit} onReset={handleReset} validated={isSuccess}>
          <CCardBody>
            <CRow>
              <CCol md="12">
                <Field
                  id="desktop_img"
                  name="desktop_img"
                  as={FormikUploadComponent}
                  label="Desktop"
                  src={banner?.desktop_img}
                  className="border border-1 rounded-1 mb-4"
                  style={{ width: '100%', height: '30vw', maxHeight: '400px' }}
                />
                <Field
                  id="mobile_img"
                  name="mobile_img"
                  as={FormikUploadComponent}
                  label="Mobile"
                  src={banner?.mobile_img}
                  className="border border-1 rounded-1 mb-4"
                  style={{ width: '100%', height: '30vw', maxHeight: '400px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}
                />
                <CFormFloating className="mb-3">
                  <Field name="report_link" type="text" placeholder="http://www.example.com/area" className={`form-control ${!!touched.report_link && !!errors.report_link && 'is-invalid'}`} />
                  <ErrorMessage name="report_link" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="report_link">Link</CFormLabel>
                </CFormFloating>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="clearfix">
            <CLoadingButton loading={isSubmitting} disabled={isSubmitting} size="lg" color="warning" className="float-end my-3" type="submit">
              Enviar
            </CLoadingButton>
            {banner && <BannerDeleteButton size="sm" className="float-end my-4 me-2" data={banner} onSuccess={handleSuccess} />}
            <CButton component={Link} color="link" className="float-start my-3" to={`/banners`}>
              Voltar
            </CButton>
          </CCardFooter>
        </CForm>
      )}
    </Formik>
  )
}

BannerForm.propTypes = {
  banner: PropTypes.object,
}

export default BannerForm
