import React, { useState } from 'react'
import axios from 'axios'
import { CCol, CRow, CCard, CCardHeader, CCardBody, CSmartTable, CImage } from '@coreui/react-pro'
import { useQuery } from '@tanstack/react-query'
import Error from 'components/Error'
import Loading from 'components/Loading'
import BannerDetail from 'components/Banner/BannerDetail'
import moment from 'moment'

async function getData() {
  const response = await axios({
    method: 'GET',
    url: '/banners',
  })

  return response.data
}

function BannerList() {
  const [details, setDetails] = useState([])
  const { isError, isSuccess, isLoading, data, refetch } = useQuery(['banners'], getData, { staleTime: 60000 })

  function handleRowClick(data, row, field, e) {
    if (true !== e) {
      setDetails((prevState) => {
        const newState = prevState.slice()
        const { id } = data

        newState.includes(id) ? newState.splice(newState.indexOf(id), 1) : newState.push(id)

        return newState
      })
    }
  }

  function handleRefetch() {
    setDetails([])
    refetch()
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Listagem</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            {isLoading && <Loading />}
            {isError && <Error />}
            {isSuccess && (
              <CSmartTable
                tableProps={{ striped: true, hover: true }}
                columns={[
                  {
                    key: 'images',
                    label: 'Imagens',
                    _style: { width: '240px' },
                  },
                  {
                    key: 'link',
                    label: 'Link',
                  },
                  {
                    key: 'createdAt',
                    label: 'Criação',
                    _style: { width: '220px' },
                  },
                ]}
                scopedColumns={{
                  images: (item) => (
                    <td className="preview">
                      <CImage rounded alt="Desktop" className="border" src={item.desktop_img} />
                      <CImage rounded alt="Mobile" className="border" src={item.mobile_img} />
                    </td>
                  ),
                  link: (item) => <td className="ellipsis">{item.report_link ? <a href={item.report_link}>{item.report_link}</a> : 'N/D'}</td>,
                  createdAt: (item) => <td className="text-middle">{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>,
                  details: (item) => <BannerDetail id={item.id} visible={details.includes(item.id)} onMutation={handleRefetch} />,
                }}
                clickableRows
                draggable
                items={data}
                sorterValue={{ column: 'created_at', state: 'desc' }}
                noItemsLabel="Nenhum resultado encontrado"
                onRowClick={handleRowClick}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default BannerList
