import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CButton, CCardBody, CCol, CCollapse, CImage, CRow } from '@coreui/react-pro'
import { createInfo } from 'components/Notifications'
import Loading from 'components/Loading'
import Error from 'components/Error'
import BannerDeleteButton from 'components/Banner/BannerDeleteButton'
import GlobalContext from 'context/GlobalContext'
import Enums from 'misc/enums'

import genericAvatar from 'assets/images/avatars/generic.svg'
import noPicture from 'assets/images/no-picture.jpg'
import moment from 'moment'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const response = await axios({
    method: 'GET',
    url: `/banners/${id}`,
  })

  return response.data
}

function BannerDetail({ visible, id, onMutation }) {
  const { isError, isSuccess, isLoading, data } = useQuery(['banner', id], getData, { staleTime: 60000, enabled: visible })

  return (
    <CCollapse visible={visible}>
      {isLoading && <Loading />}
      {isError && <Error className="m-3" />}
      {isSuccess && (
        <CCardBody>
          <CRow className="mb-3">
            <CCol md="4" className="">
              <h5>Mobile</h5>
              <CImage rounded alt="Mobile" className="w-100" src={data.mobile_img} />
            </CCol>
            <CCol md="4" className="">
              <h5>Desktop</h5>
              <CImage rounded alt="Desktop" className="w-100" src={data.desktop_img} />
            </CCol>
            <CCol md="4">
              <h5>Informações</h5>
              <dl>
                <dt>ID</dt>
                <dd>{data.id}</dd>
                <dt>Link</dt>
                <dd>
                  <a target="_blank" rel="noreferrer" href={data.report_link}>
                    {data.report_link}
                  </a>
                </dd>
                <dt>Atualização</dt>
                <dd>{moment(data.updated_at).format('DD/MM/YYYY HH:mm')}</dd>
                <dt>Criação</dt>
                <dd>{moment(data.created_at).format('DD/MM/YYYY HH:mm')}</dd>
              </dl>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" className="text-center">
              <CButton size="sm" color="primary" className="me-1" component={Link} to={`/banners/${data.id}`}>
                Editar Banner
              </CButton>
              <BannerDeleteButton data={data} onSuccess={onMutation} />
            </CCol>
          </CRow>
        </CCardBody>
      )}
    </CCollapse>
  )
}

BannerDetail.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  onMutation: PropTypes.func,
}

export default BannerDetail
