import React from 'react'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react-pro'

const menu = [
  {
    component: CNavGroup,
    name: 'Usuários',
    to: '/users',
    icon: <CIcon icon={icon.cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Listar',
        to: '/users',
        icon: <CIcon icon={icon.cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Criar',
        to: '/users/create',
        icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Banners',
    to: '/banners',
    icon: <CIcon icon={icon.cilImage} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Listar',
        to: '/banners',
        icon: <CIcon icon={icon.cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Criar',
        to: '/banners/create',
        icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Relatórios',
    to: '/reports',
    icon: <CIcon icon={icon.cilChartPie} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Listar',
        to: '/reports',
        icon: <CIcon icon={icon.cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Importar',
        to: '/reports/create',
        icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
      },
    ],
  },
]

export default menu
