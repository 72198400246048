import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Authentication from 'helpers/Authentication'

function RestrictedContent(props) {
  const { anonymous, authenticated, to, children } = props
  const isAuthenticated = Authentication.isAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if ((anonymous && isAuthenticated) || (authenticated && !isAuthenticated)) {
      navigate(to)
    }
  })

  return children
}

export default RestrictedContent
