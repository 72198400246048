import { load } from 'recaptcha-v3'

class Recaptcha {
  static async getTokenFor(action) {
    const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_KEY)
    const token = await recaptcha.execute(action)

    return token
  }
}

export default Recaptcha
