import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import axios from 'axios'
import { createRoot } from 'react-dom/client'
import Authentication from './helpers/Authentication'
import Recaptcha from './helpers/Recaptcha'
import App from './App'

axios.interceptors.request.use(async (config) => {
  if (Authentication.isAuthenticated()) {
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = Authentication.getToken()
    }
  } else {
    config.headers['Recaptcha'] = await Recaptcha.getTokenFor('CMS_LOGIN')
  }

  if (!config.baseURL) {
    config.baseURL = process.env.REACT_APP_BI4ALL_BASE_API_URL
  }

  return config
})

createRoot(document.getElementById('root')).render(<App />)
