export const logoNegative = [
  '151 82',
  `
  <path d="M3.80876 30.9299C3.67254 44.4622 13.5653 55.8193 27.1487 57.7306C36.0029 58.9736 43.7757 56.1343 50.2376 49.9194C51.1017 49.0893 51.6423 48.8169 52.6299 49.8173C58.1935 55.4618 63.8337 61.0296 69.4272 66.6443C71.5343 68.76 72.9135 71.0927 72.4622 74.3449C72.0579 77.2735 70.5424 79.253 68.0054 80.3001C65.247 81.4409 62.28 81.3388 60.0197 79.2104C55.4777 74.9408 51.1528 70.4414 46.7214 66.0527C45.5168 64.8565 46.9811 64.5372 47.4068 63.92C48.0283 63.0218 48.6881 62.8217 49.6118 63.7668C53.3791 67.6191 57.2144 71.3992 61.0243 75.2047C61.6245 75.805 62.2119 76.4052 62.9952 76.801C64.8171 77.7162 66.8263 77.38 67.9799 75.9114C69.2995 74.2385 69.3931 72.1313 67.9543 70.667C62.6972 65.3247 57.3549 60.0633 52.0807 54.7381C51.157 53.8059 50.5781 54.4657 49.9353 54.9893C46.2958 57.9435 42.1326 59.8079 37.5949 60.8679C25.8036 63.6305 13.195 58.9268 6.10317 49.1362C-3.27028 36.1955 -1.76338 18.9087 10.0492 8.16458C21.3212 -2.08577 37.5268 -2.72429 49.6161 6.47463C57.9211 12.7917 61.8501 21.4628 61.6713 31.8706C61.6032 35.8294 60.7603 39.7031 59.0193 43.3427C58.4319 44.5686 57.7976 44.8666 56.5419 44.2324C55.4096 43.6577 55.0733 43.2405 55.6309 41.8528C59.7345 31.6323 58.6617 22.0672 51.5742 13.4345C42.4774 2.35832 26.391 0.753507 15.2255 8.66688C7.67818 14.0177 3.79599 21.5564 3.8045 30.9299H3.80876Z" fill="#FFCD00"/>
  <path d="M44.7924 29.2994C44.7924 32.4877 44.7967 35.6803 44.7924 38.8686C44.7924 39.6774 45.0691 40.6182 43.6303 40.5543C42.3448 40.499 42.6087 39.6476 42.6087 38.9197C42.6002 32.824 42.6087 26.7283 42.5959 20.6325C42.5959 19.9983 42.6044 19.3385 42.4384 18.7383C42.2 17.8699 41.4594 17.508 40.6165 17.6272C39.6672 17.7635 39.1181 18.4275 39.0968 19.3938C39.0585 21.4498 39.067 23.5059 39.067 25.5576C39.067 29.3164 39.0883 33.0709 39.0585 36.8296C39.033 39.8221 37.5303 41.5802 35.0784 41.5887C32.6223 41.5972 31.1196 39.8221 31.1154 36.8679C31.1068 33.4667 31.1026 30.0613 31.1154 26.6601C31.1196 25.3874 30.8046 24.3104 29.3105 24.3189C27.8334 24.3317 27.5013 25.4044 27.5056 26.6772C27.5184 30.1507 27.5269 33.6243 27.5056 37.0978C27.4886 39.7966 25.8582 41.5972 23.4957 41.5844C21.1034 41.5717 19.6093 39.8392 19.5795 37.0297C19.5667 35.7526 19.5582 34.4756 19.5795 33.2028C19.6007 31.9811 19.158 31.0361 17.864 30.9552C16.3869 30.8659 15.9399 31.9343 15.9144 33.1901C15.8761 35.1737 15.9016 37.1574 15.9016 39.1453C15.9016 39.8562 16.025 40.5373 14.8927 40.5628C13.7562 40.5884 13.6455 40.035 13.6668 39.1325C13.7094 37.0765 13.62 35.0205 13.7008 32.9687C13.7987 30.4529 15.5568 28.7417 17.8512 28.7885C20.2137 28.8354 21.6823 30.487 21.7164 33.1645C21.7334 34.5097 21.7334 35.8591 21.7164 37.2042C21.6993 38.4429 22.1506 39.3283 23.4957 39.3454C24.8451 39.3624 25.3261 38.4727 25.3261 37.2468C25.3304 33.7732 25.3219 30.2997 25.3346 26.8262C25.3474 23.8336 26.799 22.1352 29.3233 22.1394C31.8262 22.1437 33.2821 23.8592 33.2948 26.8517C33.3076 30.1848 33.3246 33.5136 33.2863 36.8466C33.2693 38.2003 33.5077 39.3794 35.138 39.3326C36.713 39.2858 36.9855 38.0854 36.9855 36.7615C36.9727 31.1638 36.9727 25.5619 36.9855 19.9642C36.994 17.1037 38.4285 15.4435 40.8506 15.4307C43.2344 15.418 44.7626 17.142 44.7882 19.9557C44.818 23.0759 44.7967 26.1919 44.7967 29.3121L44.7924 29.2994Z" fill="white"/>
  <path d="M92.3461 31.3765C92.2738 32.794 91.0649 34.0242 89.6388 34.0242C88.2128 34.0242 87.0039 32.794 86.9315 31.3765V23.561H83.8794V31.3765C84.0369 34.4542 86.5782 36.9444 89.6388 36.9444C92.6995 36.9444 95.2365 34.4499 95.3983 31.3765V23.561H92.3504V31.3765H92.3461Z" fill="white"/>
  <path d="M71.645 10.3346C71.7217 8.91711 72.9263 7.6869 74.3566 7.6869C75.7869 7.6869 76.9916 8.91711 77.0682 10.3346V18.1501H80.116V10.3346C79.9543 7.26122 77.4215 4.771 74.3566 4.771C71.2917 4.771 68.7547 7.26122 68.5972 10.3346V18.1501H71.645V10.3346Z" fill="white"/>
  <path d="M95.1506 11.7864L92.4177 11.4458C92.4177 13.6423 90.8299 15.6005 88.4333 15.5792C86.0325 15.5622 84.3681 13.6423 84.3681 11.4544C84.3681 9.26637 86.1772 7.48277 88.395 7.48277C89.1996 7.48277 90.0467 7.71264 90.6894 8.21494L84.5299 11.6417L85.726 14.0893L94.7334 9.13015C93.7714 6.60162 91.2939 4.79248 88.3993 4.79248C84.6746 4.79248 81.6353 7.78926 81.6353 11.4629C81.6353 15.1365 84.6746 18.129 88.3993 18.129C92.124 18.129 94.9845 15.3067 95.1591 11.7864" fill="white"/>
  <path d="M99.7997 19.3125L97.5181 22.6413H100.217L103.32 19.3125H99.7997Z" fill="white"/>
  <path d="M81.9806 30.0654C81.9806 26.4386 78.9839 23.4844 75.306 23.4844C71.6281 23.4844 68.6313 26.4386 68.6313 30.0654C68.6313 33.6879 71.6324 36.6421 75.306 36.6421C76.549 36.6421 77.7196 36.3016 78.7199 35.7141L78.7327 31.2019C78.2474 32.6067 76.898 33.6198 75.3103 33.6198C73.3223 33.6198 71.7005 32.0235 71.7005 30.0654C71.7005 28.1072 73.3223 26.5067 75.3103 26.5067C77.3024 26.5067 78.9243 28.1072 78.9243 30.0654V41.9674H81.9892V30.4698H81.9764C81.9849 30.3335 81.9892 30.2016 81.9892 30.0654" fill="white"/>
  <path d="M103.46 7.43658C105.704 7.43658 107.543 9.24572 107.543 11.4635C107.543 13.6813 105.704 15.4819 103.46 15.4819C101.209 15.4819 99.3781 13.677 99.3781 11.4635C99.3781 9.24998 101.209 7.43658 103.46 7.43658ZM103.46 18.1552C107.202 18.1552 110.25 15.1499 110.25 11.4635C110.25 7.77287 107.202 4.76758 103.46 4.76758C99.7186 4.76758 96.6665 7.77287 96.6665 11.4635C96.6665 15.1541 99.7186 18.1552 103.46 18.1552Z" fill="white"/>
  <path d="M135.748 36.9442V34.0411H133.687C131.414 34.0411 129.554 32.3597 129.558 30.2483C129.558 28.2732 131.214 26.6556 133.262 26.4598H135.748V23.5566H133.262C129.482 23.7652 126.468 26.6981 126.468 30.2483C126.468 33.7985 129.711 36.9442 133.692 36.9442H135.752H135.748Z" fill="white"/>
  <path d="M151 30.0654C151 26.4386 147.999 23.4844 144.321 23.4844C140.643 23.4844 137.646 26.4386 137.646 30.0654C137.646 33.6879 140.643 36.6421 144.321 36.6421C145.568 36.6421 146.735 36.3016 147.735 35.7141L147.744 31.2019C147.258 32.6067 145.909 33.6198 144.321 33.6198C142.333 33.6198 140.711 32.0235 140.711 30.0654C140.711 28.1072 142.333 26.5067 144.321 26.5067C146.313 26.5067 147.935 28.1072 147.935 30.0654V36.9443H151V30.4698H150.987C150.996 30.3335 151 30.2016 151 30.0654Z" fill="white"/>
  <path d="M122.999 22.6375C123.867 22.6375 124.574 21.9437 124.574 21.088C124.574 20.2324 123.867 19.5386 122.999 19.5386C122.131 19.5386 121.428 20.2367 121.428 21.088C121.428 21.9394 122.139 22.6375 122.999 22.6375Z" fill="white"/>
  <path d="M100.434 23.5566H97.292V36.9442H100.434V23.5566Z" fill="white"/>
  <path d="M109.36 36.9442H112.502V28.1157C112.502 27.0642 113.374 26.2001 114.443 26.2001C115.511 26.2001 116.388 27.06 116.388 28.1157V36.9442H119.534V28.1157C119.534 25.6042 117.452 23.5566 114.907 23.5566C113.23 23.5566 111.748 24.4463 110.935 25.7702C110.118 24.4506 108.641 23.5566 106.964 23.5566C105.567 23.5566 104.312 24.1696 103.464 25.1359C102.762 25.9362 102.336 26.9791 102.336 28.1157V36.9442H105.478V28.1157C105.478 27.0642 106.351 26.2001 107.423 26.2001C108.496 26.2001 109.364 27.06 109.364 28.1157V36.9442H109.36Z" fill="white"/>
  <path d="M124.574 23.5566H121.428V36.9442H124.574V23.5566Z" fill="white"/>
  <path d="M102.992 52.5586H102.966C102.966 52.5586 102.796 53.3035 102.642 53.7505L101.719 56.3344H104.256L103.332 53.7505C103.175 53.3078 102.996 52.5586 102.996 52.5586H102.992Z" fill="white"/>
  <path d="M109.253 48.4331H71.8657C70.9079 48.4331 70.1289 49.2164 70.1289 50.1699V61.1737C70.1289 62.1315 70.9122 62.9104 71.8657 62.9104H109.253C110.211 62.9104 110.99 62.1272 110.99 61.1737V50.1699C110.99 49.2121 110.207 48.4331 109.253 48.4331ZM82.0224 52.3323H81.8521C81.635 52.3323 81.5286 52.3791 81.4562 52.5962L78.7872 59.786H77.5144L74.8454 52.5962C74.7731 52.3791 74.6539 52.3323 74.4495 52.3323H74.2793V51.2979H74.9518C75.5521 51.2979 75.7564 51.4299 75.9479 51.996L77.8124 57.2276C77.9699 57.6831 78.1359 58.394 78.1359 58.394H78.1615C78.1615 58.394 78.319 57.6831 78.485 57.2276L80.3495 51.996C80.5538 51.4299 80.7453 51.2979 81.3455 51.2979H82.0181V52.3323H82.0224ZM85.4746 52.3323H84.6446V58.7516H85.4746V59.786H82.6737V58.7516H83.5037V52.3323H82.6737V51.2979H85.4746V52.3323ZM89.2036 59.9307C87.3987 59.9307 86.3898 58.6664 86.3898 58.6664L87.1007 57.8151C87.1007 57.8151 87.9648 58.8367 89.2291 58.8367C90.0209 58.8367 90.6849 58.3557 90.6849 57.5724C90.6849 55.7931 86.5857 56.0698 86.5857 53.4263C86.5857 52.1535 87.7052 51.1532 89.3015 51.1532C90.2039 51.1532 91.7193 51.5618 91.7193 52.7154V53.4136H90.6126V53.0177C90.6126 52.575 89.9741 52.2472 89.3015 52.2472C88.3863 52.2472 87.8116 52.7537 87.8116 53.388C87.8116 55.1078 91.8981 54.7204 91.8981 57.5128C91.8981 58.8112 90.8893 59.9307 89.2036 59.9307ZM98.5217 52.3323H96.8147V59.786H95.6143V52.3323H93.9073C93.7371 52.3323 93.6434 52.4175 93.6434 52.5962V53.1369H92.575V52.0173C92.575 51.4767 92.7537 51.2979 93.2943 51.2979H99.1389C99.6795 51.2979 99.8583 51.4767 99.8583 52.0173V53.1369H98.7899V52.5962C98.7899 52.4175 98.692 52.3323 98.5259 52.3323H98.5217ZM106.856 59.786H106.184C105.584 59.786 105.392 59.6412 105.196 59.0878L104.558 57.3426H101.433L100.795 59.0878C100.59 59.6412 100.399 59.786 99.7987 59.786H99.1389V58.7516H99.3092C99.5263 58.7516 99.6327 58.7047 99.7051 58.4876L102.361 51.2979H103.626L106.282 58.4876C106.354 58.7047 106.473 58.7516 106.678 58.7516H106.856V59.786Z" fill="white"/>
`,
]
