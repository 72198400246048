class OptionParser {
  static fromEnum(source) {
    return Object.keys(source).reduce((options, key) => {
      options.push({ value: key, label: source[key] })
      return options
    }, [])
  }
}

export default OptionParser
