import React from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CCard, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import Error from 'components/Error'
import Loading from 'components/Loading'
import BannerForm from 'components/Banner/BannerForm'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const response = await axios({
    method: 'GET',
    url: `/banners/${id}`,
  })

  return response.data
}

function BannerEdit() {
  const { id } = useParams()
  const { isError, isSuccess, isLoading, data } = useQuery(['banner', id], getData, { staleTime: 60000 })
  const navigate = useNavigate()

  if (isSuccess && !data?.id) {
    navigate('/not-found')
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard>
          <CCardHeader>
            <strong>Formulário</strong> <small>Edição</small>
          </CCardHeader>
          {isLoading && <Loading />}
          {isError && <Error className="m-3" />}
          {isSuccess && <BannerForm banner={data} />}
        </CCard>
      </CCol>
    </CRow>
  )
}

export default BannerEdit
