import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CNav, CNavItem, CNavLink, CRow } from '@coreui/react-pro'
import UserForm from 'components/User/UserForm'

function UserCreate() {
  return (
    <CRow>
      <CCol xs={12}>
        <CCard>
          <CCardHeader>
            <strong>Formulário</strong> <small>Criação</small>
          </CCardHeader>
          <CCardBody>
            <CNav variant="underline">
              <CNavItem>
                <CNavLink active>Dados da Conta</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink disabled>Relatórios</CNavLink>
              </CNavItem>
            </CNav>
          </CCardBody>
          <UserForm />
        </CCard>
      </CCol>
    </CRow>
  )
}

export default UserCreate
