import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createError } from 'components/Notifications'
import Authentication from 'helpers/Authentication'

const GlobalContext = createContext({})

function Provider(props) {
  const { children } = props
  const [toast, addToast] = useState()
  const [user, setUser] = useState()
  const [token, setToken] = useState(Authentication.getToken())
  const [sidebarShow, setSidebarShow] = useState(true)
  const [theme, setTheme] = useState('light')
  const queryClient = new QueryClient()

  useEffect(() => {
    const payload = Authentication.setToken(token)

    if (payload) {
      setUser(payload)
    } else if (token !== null) {
      Authentication.setToken(null)
      addToast(createError('Dados de autenticação inválidos ou permissão insuficiente.'))
    }
  }, [token])

  return (
    <GlobalContext.Provider value={{ toast, addToast, user, setToken, sidebarShow, setSidebarShow, theme, setTheme }}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GlobalContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
}

export { GlobalContext as default, Provider }
