import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CCard, CCardBody, CCardHeader, CCol, CNav, CNavItem, CNavLink, CRow } from '@coreui/react-pro'
import Error from 'components/Error'
import Loading from 'components/Loading'
import UserForm from 'components/User/UserForm'
import UserReport from 'components/User/UserReport'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const response = await axios({
    method: 'GET',
    url: `/users/${id}`,
  })

  return response.data
}

function UserEdit() {
  const { id } = useParams()
  const { isError, isSuccess, isLoading, data } = useQuery(['user', id], getData, { staleTime: 60000 })
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('accountTab')

  function handleTabChange(e) {
    setActiveTab(e.target.id)
  }

  if (isSuccess && !data?.id) {
    navigate('/not-found')
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard>
          <CCardHeader>
            <strong>Formulário</strong> <small>Edição</small>
          </CCardHeader>
          <CCardBody>
            <CNav variant="underline">
              <CNavItem>
                <CNavLink id="accountTab" active={activeTab === 'accountTab'} onClick={handleTabChange}>
                  Dados da Conta
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink id="reportsTab" active={activeTab === 'reportsTab'} onClick={handleTabChange}>
                  Relatórios
                </CNavLink>
              </CNavItem>
            </CNav>
          </CCardBody>
          {isLoading && <Loading />}
          {isError && <Error className="m-3" />}
          {isSuccess && activeTab === 'accountTab' && <UserForm user={data} />}
          {isSuccess && activeTab === 'reportsTab' && <UserReport user={data} />}
        </CCard>
      </CCol>
    </CRow>
  )
}

export default UserEdit
