import React from 'react'
import { CFooter } from '@coreui/react-pro'

function AppFooter() {
  return (
    <CFooter>
      <div>
        <a href="https://www.hypera.com.br/" target="_blank" rel="noopener noreferrer">
          Hypera Pharma
        </a>
        <span className="ms-1">&copy; 2002 - 2023.</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a href="http://thegoodfellas.com.br/" target="_blank" rel="noopener noreferrer">
          The Godfellas &amp; Coderama
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
