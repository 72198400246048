import React from 'react'
import { CSpinner } from '@coreui/react-pro'

function Loading() {
  return (
    <div className="d-flex justify-content-center">
      <CSpinner className="m-5" />
    </div>
  )
}

export default Loading
