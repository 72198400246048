import React, { useContext } from 'react'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'
import { CToaster, CToast, CToastHeader, CToastBody, CBadge } from '@coreui/react-pro'
import UserContext from 'context/GlobalContext'

function create(message, title, type = 'info') {
  const icons = {
    info: icon.cilInfo,
    warning: icon.cilWarning,
    success: icon.cilList,
    danger: icon.cilXCircle,
  }

  return (
    <CToast>
      <CToastHeader closeButton>
        <CBadge color={type}>
          <CIcon icon={icons[type]} />
        </CBadge>
        <strong className="me-auto ps-2">{title}</strong>
      </CToastHeader>
      <CToastBody>{message.replace('Invariant failed:', '').trim()}</CToastBody>
    </CToast>
  )
}

function createInfo(message, title = 'Informação') {
  return create(message, title, 'info')
}

function createWarning(message, title = 'Atenção') {
  return create(message, title, 'warning')
}

function createError(message, title = 'Erro') {
  return create(message, title, 'danger')
}

function createSuccess(message, title = 'Sucesso') {
  return create(message, title, 'success')
}

function Notifications() {
  const { toast } = useContext(UserContext)

  return <CToaster push={toast} placement="top" />
}

export { Notifications as default, createError, createInfo, createSuccess, createWarning }
