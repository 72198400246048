import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import { cilCopy } from '@coreui/icons'
import { useQuery } from '@tanstack/react-query'
import { CAvatar, CButton, CCardBody, CCol, CCollapse, CImage, CRow, CTooltip } from '@coreui/react-pro'
import { createInfo } from 'components/Notifications'
import Loading from 'components/Loading'
import Error from 'components/Error'
import UserDeleteButton from 'components/User/UserDeleteButton'
import UserResendEmailButton from 'components/User/UserResendEmailButton'
import GlobalContext from 'context/GlobalContext'
import Enums from 'misc/enums'

import genericAvatar from 'assets/images/avatars/generic.svg'
import noPicture from 'assets/images/no-picture.jpg'

async function getData({ queryKey }) {
  const [key, id] = queryKey

  const [responseUser, responseReport] = await Promise.all([
    axios({
      method: 'GET',
      url: `/users/${id}`,
    }),
    axios({
      method: 'POST',
      url: `/reports/list-by-user-with-card`,
      data: {
        user_id: id,
      },
    }),
  ])

  return { ...responseUser.data, reports: responseReport.data }
}

function UserDetail({ visible, id, onMutation }) {
  const { addToast } = useContext(GlobalContext)
  const { isError, isSuccess, isLoading, data } = useQuery(['user', id], getData, { staleTime: 60000, enabled: visible })

  return (
    <CCollapse visible={visible}>
      {isLoading && <Loading />}
      {isError && <Error className="m-3" />}
      {isSuccess && (
        <CCardBody>
          <CRow>
            <CCol md="2" className="d-flex justify-content-center align-items-top">
              <CAvatar src={data.file_url || genericAvatar} size="xl" className="mt-2" />
            </CCol>
            <CCol md="3">
              <h4>{data.name}</h4>
              <p className="text-muted">
                <a href={`mailto:${data.email}`}>{data.email}</a>{' '}
                <CIcon
                  icon={cilCopy}
                  onClick={() => {
                    navigator.clipboard.writeText(data.email)
                    addToast(createInfo(`O e-mail "${data.email}" foi copiado para sua area de transferência.`))
                  }}
                />
              </p>
              <dl>
                <dt>ID</dt>
                <dd>{data.id}</dd>
                <dt>Perfil</dt>
                <dd>{Enums.roles[data.roles] || 'N/D'}</dd>
                <dt>Cargo</dt>
                <dd>{data.position || 'N/D'}</dd>
                <dt>Empresa</dt>
                <dd>{data.company || 'N/D'}</dd>
                <dt>Regional</dt>
                <dd>{data.regional || 'N/D'}</dd>
                <dt>Estado</dt>
                <dd>{data.state || 'N/D'}</dd>
              </dl>
            </CCol>
            <CCol md="7">
              <h6 className="text-center mb-4">Relatórios</h6>
              <div className="d-flex justify-content-center align-items-center flex-wrap gap-4">
                {data.reports.length === 0 ? (
                  <p className="text-muted mt-5 pt-5">Não há reports para este usuário.</p>
                ) : (
                  data.reports.map((report) => (
                    <div style={{ minWidth: '150px', width: 'calc(25% - 1.5rem)' }} key={`${data.id}-${report.id}`}>
                      <CImage rounded className="w-100" alt={report.name} src={report.file_url || noPicture} />
                      <p className="mt-2 px-1 text-center w-100 text-nowrap text-truncate">
                        <CTooltip content={`${report.name} ${report.roles && `(${report.roles})`}`} placement="bottom">
                          <small>
                            {report.name} {report.roles && `(${report.roles})`}
                          </small>
                        </CTooltip>
                      </p>
                    </div>
                  ))
                )}
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" className="text-center">
              <CButton size="sm" color="primary" className="me-1" component={Link} to={`/users/${data.id}`}>
                Editar Usuário
              </CButton>
              <UserResendEmailButton data={data} className="me-1" />
              <UserDeleteButton data={data} onSuccess={onMutation} />
            </CCol>
          </CRow>
        </CCardBody>
      )}
    </CCollapse>
  )
}

UserDetail.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  onMutation: PropTypes.func,
}

export default UserDetail
