import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CInputGroup, CInputGroupText, CLoadingButton, CRow } from '@coreui/react-pro'
import { cilUser } from '@coreui/icons'
import { Formik, Field, ErrorMessage } from 'formik'
import { createError, createSuccess } from 'components/Notifications'
import RestrictedContent from 'components/RestrictedContent'
import FormikErrorParser from 'helpers/FormikErrorParser'
import GlobalContext from 'context/GlobalContext'

import { logo } from 'assets/brand/logo'

const Forgot = () => {
  const [done, setDone] = useState()
  const { addToast } = useContext(GlobalContext)
  const initialValues = { email: '' }

  async function handleSubmit(data, { setSubmitting, setErrors }) {
    try {
      await axios({
        method: 'POST',
        url: '/users/forgot-password',
        data: { emailTo: data.email, appUrl: data.roles === 'admin' ? process.env.REACT_APP_CMS_URL : process.env.REACT_APP_SITE_URL },
      })

      addToast(createSuccess('Um e-mail foi enviado contendo um link para redefinição da sua senha.'))
      setDone(true)
    } catch (e) {
      if (e.name === 'AxiosError') {
        switch (e.response.status) {
          case 500:
          case 422:
            setErrors(FormikErrorParser.from(e))
            break
          case 404:
            addToast(createError(e.response.data.errors?.message))
            break
          default:
            addToast(createError('Erro ao submeter as informações ao servidor. Por favor, tente novamente.'))
            break
        }
      } else {
        addToast(createError(e.message))
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <RestrictedContent anonymous to="/">
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center login-background">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="d-flex justify-content-center bg-primary p-4">
                  <CIcon className="sidebar-brand-full" icon={logo} height={35} />
                </CCard>
                <CCard className="p-4">
                  <CCardBody>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ errors, touched, isSubmitting, handleSubmit, handleReset }) => (
                        <CForm onSubmit={handleSubmit} onReset={handleReset} validated={done}>
                          <h1>Recuperar Senha</h1>
                          <p className="text-medium-emphasis">Preencha o campo para receber um e-mail de recuperação de acesso.</p>
                          <p className="text-medium-emphasis">Você deve possuir um cadastro prévio.</p>
                          <CInputGroup className="mb-3">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <Field name="email" type="text" placeholder="E-mail" autoComplete="username" className={`form-control ${!!touched.email && !!errors.email && 'is-invalid'}`} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </CInputGroup>
                          <CRow>
                            <CCol xs={6}>
                              <CLoadingButton color="primary" className="px-4" type="submit" disabled={isSubmitting || done} loading={isSubmitting}>
                                Recuperar
                              </CLoadingButton>
                            </CCol>
                            <CCol xs={6} className="text-end">
                              <CButton component={Link} color="link" className="px-0" to="/login">
                                Voltar ao Login
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      )}
                    </Formik>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </RestrictedContent>
  )
}

export default Forgot
