import React from 'react'
import { CCard, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import BannerForm from 'components/Banner/BannerForm'

function BannerCreate() {
  return (
    <CRow>
      <CCol xs={12}>
        <CCard>
          <CCardHeader>
            <strong>Formulário</strong> <small>Criação</small>
          </CCardHeader>
          <BannerForm />
        </CCard>
      </CCol>
    </CRow>
  )
}

export default BannerCreate
