import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Formik, Field, ErrorMessage } from 'formik'
import { CButton, CCardBody, CCardFooter, CCol, CForm, CFormFloating, CFormLabel, CFormSelect, CLoadingButton, CRow } from '@coreui/react-pro'
import { createError, createSuccess } from 'components/Notifications'
import FormikErrorParser from 'helpers/FormikErrorParser'
import FormikUploadComponent from 'components/FormikUploadComponent'
import ReportDeleteButton from 'components/Report/ReportDeleteButton'
import GlobalContext from 'context/GlobalContext'

async function sendData({ values: { id, file, file_url, ...values }, helpers: { setSubmitting, setErrors, setFieldError }, data }) {
  try {
    const dataset_id = data.filter((item) => item.id === values.report_id).pop().datasetId

    if (!id) {
      values = { ...values, firm_id: process.env.REACT_APP_API_FIRM_ID }
    }

    const response = await axios({
      method: id ? 'PUT' : 'POST',
      url: id ? `/report-cards/${id}/update` : '/report-cards/store',
      data: { ...values, group_id: process.env.REACT_APP_API_REPORT_GROUP, dataset_id },
    })

    if ([null, undefined, ''].includes(file)) {
      return
    }

    try {
      await axios({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'PUT',
        url: `/report-cards/${id || response.data.id}/upload`,
        data: { file },
      })
    } catch (e) {
      setFieldError('file', 'Erro ao enviar imagem.')
      throw Error('Confira os campos destacados em vermelho.')
    }
  } catch (e) {
    if (e.name === 'AxiosError') {
      switch (e.response?.status) {
        case 500:
        case 422:
          setErrors(FormikErrorParser.from(e))
          throw Error('Erro de validação. Confira os campos destacados em vermelho.')
        case 404:
          throw Error(e.response.data.errors?.message)
        default:
          throw Error('Não foi possível se comunicar com o servidor ou o retorno foi inesperado. Por favor, informe o ocorrido.')
      }
    } else {
      throw e
    }
  } finally {
    setSubmitting(false)
  }
}

async function getData({ queryKey }) {
  const [key, ignored, id] = queryKey

  const [responseCardsList, responseReportsList] = await Promise.all([
    axios({
      method: 'POST',
      url: '/report-cards/list-by-firm',
      data: { firm_id: process.env.REACT_APP_API_FIRM_ID },
    }),
    axios({
      method: 'GET',
      url: `/powerbi/groups/${process.env.REACT_APP_API_REPORT_GROUP}/reports`,
    }),
  ])

  return responseReportsList.data.filter((report) => report.id === id || !responseCardsList.data.find((card) => card.report_id === report.id))
}

function ReportForm(props) {
  const { report } = props
  const { addToast } = useContext(GlobalContext)
  const { isError, isSuccess, isLoading, data } = useQuery(['reports', 'importable', report?.report_id], getData, { staleTime: 60000 })
  const { isSuccess: isUpdated, mutate } = useMutation(sendData, { onSuccess: handleSuccess, onError: handleError })
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const initialValues = report || { name: '', report_id: '' }

  function handleSubmit(values, helpers) {
    mutate({ values, helpers, data })
  }

  function handleSuccess() {
    queryClient.invalidateQueries({ queryKey: ['reports'] })
    addToast(createSuccess(report ? 'Relatório atualizado com sucesso.' : 'Relatório cadastrado com sucesso.'))
    navigate('/reports')
  }

  function handleError(e) {
    addToast(createError(e.message))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, errors, touched, isSubmitting, handleSubmit, handleReset, setFieldValue }) => (
        <CForm onSubmit={handleSubmit} onReset={handleReset} validated={isUpdated}>
          <CCardBody>
            <CRow>
              <CCol xl="3" xxl="2">
                <Field
                  id="file"
                  name="file"
                  as={FormikUploadComponent}
                  src={report?.file_url}
                  className="d-flex justify-content-center align-items-center border border-2 rounded-4  position-relative mt-0 mx-auto mb-4 mb-xl-0"
                  style={{ width: '135px', height: '135px' }}
                />
                <ErrorMessage name="file" component="div" className="invalid-feedback" />
              </CCol>
              <CCol xl="9" xxl="10">
                <CFormFloating className="mb-3">
                  <Field name="report_id">
                    {({ field: { onChange, ...field }, meta }) => (
                      <CFormSelect
                        disabled={isLoading}
                        className={`form-control ${!!meta.touched && !!meta.error && 'is-invalid'}`}
                        options={['Selecione...', ...(data || []).map((item) => ({ value: item.id, label: item.name }))]}
                        onChange={(e) => {
                          if (values.name === '') {
                            const matching = data.filter((item) => item.id === e.target.value)

                            if (matching && matching.length > 0) {
                              setFieldValue('name', matching[0].name)
                            }
                          }

                          onChange(e)
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="report_id" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="report_id">Relatório PowerBI</CFormLabel>
                </CFormFloating>
                <CFormFloating className="mb-3">
                  <Field name="name" type="text" placeholder="John Doe" className={`form-control ${!!touched.name && !!errors.name && 'is-invalid'}`} />
                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  <CFormLabel htmlFor="name">Nome de Exibição</CFormLabel>
                </CFormFloating>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="clearfix">
            <CLoadingButton loading={isSubmitting} disabled={isSubmitting} size="lg" color="warning" className="float-end my-3" type="submit">
              Enviar
            </CLoadingButton>
            {report && <ReportDeleteButton size="sm" className="float-end my-4 me-2" data={report} onSuccess={handleSuccess} />}
            <CButton component={Link} color="link" className="float-start my-3" to={`/reports`}>
              Voltar
            </CButton>
          </CCardFooter>
        </CForm>
      )}
    </Formik>
  )
}

ReportForm.propTypes = {
  report: PropTypes.object,
}

export default ReportForm

///powerbi/groups/${process.env.REACT_APP_API_REPORT_GROUP}/reports
