import Dashboard from 'views/dashboard/Dashboard'
import BannerList from 'views/banners/BannerList'
import BannerEdit from 'views/banners/BannerEdit'
import BannerCreate from 'views/banners/BannerCreate'
import UserList from 'views/users/UserList'
import UserEdit from 'views/users/UserEdit'
import UserCreate from 'views/users/UserCreate'
import ReportList from 'views/reports/ReportList'
import ReportEdit from 'views/reports/ReportEdit'
import ReportCreate from 'views/reports/ReportCreate'
import ReportEmbed from 'views/reports/ReportEmbed'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/banners/create', name: 'Criar', element: BannerCreate },
  { path: '/banners/:id', name: 'Editar', element: BannerEdit },
  { path: '/banners', name: 'Banners', element: BannerList },
  { path: '/users/create', name: 'Criar', element: UserCreate },
  { path: '/users/:id', name: 'Editar', element: UserEdit },
  { path: '/users', name: 'Usuários', element: UserList },
  { path: '/reports/embed', name: 'Visualizar', element: ReportEmbed },
  { path: '/reports/create', name: 'Importar', element: ReportCreate },
  { path: '/reports/:id', name: 'Editar', element: ReportEdit },
  { path: '/reports', name: 'Relatórios', element: ReportList },
]

export default routes
